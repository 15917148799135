import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';

export const formatObjectByType = (items: any): any => {
    const result = {
        suggested: [],
        connected: [],
    };

    items.forEach((item) => {
        if (item.type.id === EDrugManufacturerTypeId.vendor && item.credential_types?.[0].api_credential_id === null) {
            result.suggested.push(item);
        } else if (item.credential_types?.[0].api_credential_id !== null) {
            result.connected.push(item);
        } else {
            result.suggested.push(item);
        }
    });

    return result;
};
