// next
import { useRouter } from 'next/router';
import Image from 'next/image';
// react
import { useState } from 'react';
// component
import { useToast } from '@commons/EXComponentsLibrary';
// rsuite
import { IconButton } from 'rsuite';
// owner css
import style from './ExButtonONOFF.module.css';
// utils
import { auxiliaryPaper } from './utils';
// interface
import { IExButtonONOFFProps, TExButtonONOFF } from './ExButtonONOFF.interface';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';

const ExButtonONOFF: React.FC<IExButtonONOFFProps> = ({ initialState = '', drugManufacturerId, disabledButton }) => {
    // Next
    const router = useRouter();
    const { query } = router;
    // hook
    const toaster = useToast();
    // context
    const { setIsFetchingStateOnOff } = useModalCredentialsContext();
    // State for button visibility
    const [visibility, setVisibility] = useState<TExButtonONOFF>(initialState);

    // Auxiliary function for getting icon and styles
    const { getStylesAndIcons, handlerToggleSubmit } = auxiliaryPaper();
    const { icon, classNameStyle } = getStylesAndIcons(style, visibility);

    // Click handler to toggle visibility state
    const handleToggle = () => {
        handlerToggleSubmit(drugManufacturerId, query, setVisibility, toaster, setIsFetchingStateOnOff);
    };

    return (
        <IconButton
            className={`${style['base-container']} ${classNameStyle}`}
            icon={
                <Image
                    src={icon}
                    width={16}
                    height={16}
                    alt={`${visibility} state icon`} // Dynamic alt text for accessibility
                    draggable={false}
                />
            }
            onClick={() => handleToggle()} // Toggle state on button click
            aria-label={`Turn ${visibility === 'ON' ? 'on' : 'off'}`} // Accessibility label
            disabled={visibility === '' || visibility === null || disabledButton}
        />
    );
};

export default ExButtonONOFF;
