import { PostProcessRequest } from 'api';
import { deleteItem, getEnabledDrugManufacturers } from 'api/orders';
import { DrugManufacturer, Product } from 'common/types';
import { EX3485, EX3771, EX4073, EX4927, EX5942, EX6410 } from 'config/flags';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { DrugManufacturerStatus } from '@commons/modules/EfficientPurchase/FileLoad/LoadingFile';
import { ProductInterface } from '@commons/modules/EfficientPurchase/ProductComparisonV2/components/ProductComparisonItem/ProductItemRow';
import useWindowDimensions from '@commons/modules/EfficientPurchase/ProductComparisonV2/components/ProductComparisonTable/ProductTableHead/ModalWithPriceWithOutSelection/WindowDimensions';
import { useStatesFilters } from 'utils/hooks/use-states-filters';
import { isEmptyObjectAtrribute } from 'utils/object';
import { useCredentialErrors } from './CredentialsErrors/CredentialsErrors';
import { useUser } from 'utils/hooks';
import { DrugManufacturersId, StatusCdo } from 'utils/interfaces';
import { COUNTRY } from 'utils/country';
import { useDrugManufacturersData } from './productComparison/hooks';

interface ProductComparisonParams {
    children: React.ReactNode;
    orderId: any;
    drugManufacturerStatusList: DrugManufacturerStatus[];
    remainingAttemptsInitValue: number;
    pointOfSale: any;
}

export type ItemCheckbox = {
    label: string;
    id: number;
    name: 'Sin stock' | 'Con descuento' | 'Con genéricos';
    isChecked: boolean;
};

export interface EstimatedSavingsProps {
    youCanSave: number;
    youAreSaving: number;
}

export type InsufficientCreditWarning = {
    id: number;
    isWarningOpen: boolean;
};

export interface ProductComparisonProps extends ProductComparisonParams {
    remainingAttempts: number;
    setRemainingAttempts(value: number): void;
    retryLoading: boolean;
    setRetryLoading(value: boolean): void;
    loading: boolean;
    setLoading(value: boolean): void;
    isAnyDrugManufacturerFailed(): boolean;
    isFailedConnectionByDrugManufacturerId(id: any): boolean;
    getProductSelectedByDrugManufacturerId(id: Product['drug_manufacturer_id']): ProductInterface[];
    getWithStockDrugManfucaturersById(id: Product['drug_manufacturer_id']): ProductInterface[];
    getProductSelected(): PostProcessRequest[];
    estimatedSavings: EstimatedSavingsProps;
    productHasCheckedQuantity(): ProductInterface[];
    allProductHasChecked(): boolean;
    getProductQuantity(): number;
    subtotal: number;
    getSubtotalById(id: DrugManufacturer['id']): number;
    products: ProductInterface[];
    setProductInfo(product: ProductInterface): void;
    productGroups: ProductInterface[];
    setProductGroups(products: ProductInterface[]): void;
    drugManufacturersFailedList: DrugManufacturerStatus[];
    setDrugManufacturersFailedList(drugManufacturers: DrugManufacturerStatus[]): void;
    setColumnWidth(width: number): void;
    columnWidth: number;
    setColumnMargin(margin: number): void;
    columnMargin: number;
    aSingleDrugsHasStock: boolean;
    removeItem(barcode: number, orderId: number): void;
    isEnabledDrugManufacturer(id: number): boolean;
    enabledDrugManufacturer: any;
    setEnabledDrugManufacturer(drugManufacturer: any): void;
    enabledOnOff: boolean;
    setEnabledOnOff: (value: boolean) => void;
    lastDrugManufacturerEnabled: () => any;
    cleanProducts: () => void;
    setUndoTimeout(option: any);
    undoItem(barcode: number, orderId: number, index?: number): void;
    drugManufacturer: any;
    selectAllProductByDMId(drug_manufacturer_id: number): void;
    selectAllState: any;
    setSelectAllState: (value: any) => void;
    moreThanFiveDrugManufacturers: () => boolean;
    disabledButtonMakeOrderByDelete: boolean;
    showModalRecommended: () => boolean;
    setShowModalRecommended: () => void;
    hiddenModalRecommended: boolean;
    setHiddenModalRecommended: (v: boolean) => void;
    recommendations: any;
    setRecommendations: (recommendations: any) => void;
    recommendationsVisible: any;
    setRecommendationsVisible: (recommendations: any) => void;
    setIsFetchRehydrate: (val: boolean) => void;
    isFetchRehydrate: boolean;
    isManualChecked?: boolean;
    setIsManualChecked: (v: boolean) => void;
    filtersByTerms?: () => any;
    getUnitsSelectedByDrugManufacturerId: (id: Product['drug_manufacturer_id']) => any;
    units: any;
    updateProductGroup?: boolean;
    allProductsOutOfStock: boolean;
    setAllProductsOutOfStock?: (value: boolean) => void;
    minimunPurchase: any[];
    removeMinimunPurchase: (v: number) => void;
    addMinimunPurchase: (v: number) => void;
    withoutSearchings?: any;
    drugManufacturerBelowAmount?: () => any;
    setSearchTerms?: any;
    searchTerms?: any;
    setIsCheck?: any;
    isCheck?: any;
    handleClearFilters?: any;
    modalPriceWithOutSelection?: any;
    setOpenModalConfigDistribution?: (value: boolean) => void;
    openModalConfigDistribution?: boolean;
    setOpenModalConfigPriorities?: (value: boolean) => void;
    openModalConfigPriorities?: boolean;
    setShowModalPriceWithOutSelection?: (value: boolean) => void;
    showModalPriceWithOutSelection?: boolean;
    showModalMinimunPurchase: boolean;
    handlerModalMinimunPurchase: () => void;
    isCreditAvailable?: any;
    creditLimitReach?: any;
    getCreditById?: any;
    productUncheckedWithMinOrMaxRequired?: any;
    showModalMinMaxQuantity?: boolean;
    setShowModalMinMaxQuantity?: (value: boolean) => void;
    handlerModalMinMaxQuantity?: () => void;
    drugManufacturerBelowOrUpperQuantity?: () => any;
    getAllUncheckedProducts?: () => ProductInterface[];
    manuallySelectedProductIds?: number[];
    setManuallySelectedProductIds?: React.Dispatch<React.SetStateAction<number[]>>;
    recommendationLimit: boolean;
    setRecommendationLimit: (value: boolean) => void;
    filters: ItemCheckbox[];
    setFilters: React.Dispatch<React.SetStateAction<ItemCheckbox[]>>;
    showChildren: any;
    setShowChildren?: (value: boolean) => void;
    allGenerics?: any;
    setAllGenerics: (value: any) => any;
    updateParentProduct?: any;
    setUpdateParentProduct?: any;
    isThereAnyGenericProductWithZero: () => boolean;
    insufficientCreditIDs: InsufficientCreditWarning[];
    setInsufficientCreditIDs: Dispatch<SetStateAction<InsufficientCreditWarning[]>>;
    openedInsufficientCreditIDs: number[];
    setOpenedInsufficientCreditIDs: Dispatch<SetStateAction<number[]>>;
    manuallyOpenedCreditTip: number;
    setManuallyOpenedCreditTip: Dispatch<SetStateAction<number>>;
    addNewRecommendation: (item: ProductInterface) => void;
    loadingProduct?: boolean;
    setLoadingProduct?: (v: boolean) => void;
}

const ProductComparisonContext = React.createContext<Omit<ProductComparisonProps, 'children'>>(null);

export function ProductComparisonProvider({ children, ...props }: ProductComparisonParams) {
    // hooks
    const { isFilteringByOutofStock, setIsFilteringByOutofStock } = useStatesFilters();
    // states
    const [insufficientCreditIDs, setInsufficientCreditIDs] = useState<InsufficientCreditWarning[]>([]);
    const [manuallyOpenedCreditTip, setManuallyOpenedCreditTip] = useState<number>();
    const [openedInsufficientCreditIDs, setOpenedInsufficientCreditIDs] = useState<number[]>([]);
    const [allProductsOutOfStock, setAllProductsOutOfStock] = useState(false);
    const [remainingAttempts, setRemainingAttempts] = React.useState<number>(props?.remainingAttemptsInitValue);
    const [columnWidth, setColumnWidth] = React.useState<number>(142);
    const [columnMargin, setColumnMargin] = React.useState<number>(31);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [retryLoading, setRetryLoading] = React.useState<boolean>(false);
    const [subtotal, setSubtotal] = React.useState<number>(0);
    const [products, setProducts] = React.useState<ProductInterface[]>([]);
    const [productGroups, setProductGroups] = React.useState<ProductInterface[]>([]);
    const [aSingleDrugsHasStock, setASingleDrugsHasStock] = React.useState<boolean>(false);
    const [estimatedSavings, setEstimatedSavings] = React.useState<EstimatedSavingsProps>({
        youAreSaving: 0,
        youCanSave: 0,
    });
    const [drugManufacturersFailedList, setDrugManufacturersFailedList] = React.useState(
        props?.drugManufacturerStatusList,
    );
    const { isFetchingUpdate, extractedDataError } = useCredentialErrors();
    const [undoTimeout, setUndoTimeout] = React.useState(false);
    const [deleteItemTimeout, setDeleteItemTimeout] = React.useState(null);
    const [drugManufacturer, setDrugManufacturer] = React.useState([]);
    const [enabledDrugManufacturer, setEnabledDrugManufacturer] = React.useState(
        drugManufacturer?.map(({ id, name }) => {
            return { id: id, name: name, enabled: true, credit_available: null };
        }),
    );
    const [enabledOnOff, setEnabledOnOff] = React.useState<boolean>(false);
    const [selectAllState, setSelectAllState] = React.useState(null);
    const { width } = useWindowDimensions();
    const [disabledButtonMakeOrderByDelete, setDisabledButtonMakeOrderByDelete] = React.useState<boolean>(false);
    const [hiddenModalRecommended, setHiddenModalRecommended] = React.useState<boolean>(false);
    // run fill() when checked manual is activate
    const [isManualChecked, setIsManualChecked] = React.useState<boolean>(false);
    const [manuallySelectedProductIds, setManuallySelectedProductIds] = React.useState<number[]>([]);
    const [searchTerms, setSearchTerms] = useState('');
    const [isCheck, setIsCheck] = useState({});
    const [filters, setFilters] = useState<ItemCheckbox[]>([]);
    const [originalProductGroups, setOriginalProductGroups] = React.useState<ProductInterface[]>([]);
    const [updateProductGroup, setUpdateProductGroup] = useState(false);
    const [units, setUnits] = useState<any>([]);
    const [withoutSearchings, setWithoutSearchings] = useState(false);
    // MONTO MINIMO STATES
    const [minimunPurchase, setMinimunPurchase] = useState<any>([]);
    const [showModalMinimunPurchase, setShowModalMinimunPurchase] = useState<boolean>(false);
    // END MONTO MINIMO STATES
    // MIN AND MAX QUANTITY STATES
    const [showModalMinMaxQuantity, setShowModalMinMaxQuantity] = useState<boolean>(false);
    /* #region new state by recommendation limit in table v3 */
    const [recommendationLimit, setRecommendationLimit] = useState<boolean>(false);
    /* #endregion */
    const [showChildren, setShowChildren] = useState<any>({});
    const [allGenerics, setAllGenerics] = useState([]);

    const [updateParentProduct, setUpdateParentProduct] = useState([]);
    // new hook by drugmanufactures with cache
    const {
        drugManufacturersData,
        oneDisabledDrugManufacturerData,
        mutateDrugManufacturers,
    } = useDrugManufacturersData();
    const [loadingProduct, setLoadingProduct] = useState<boolean>(false);

    const { user } = useUser({});
    const EX4856 = user?.EX4856;
    const EX4562 = user?.EX4562;
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    const EX5266 = user?.EX5266; //ff hot fix calculate footer
    const EX5267 = user?.EX5267; //ff hot fix calculate footer when select all column
    const EX4927_FFS = user?.EX4927 && EX4927; //ff redisegn v3
    const EX5182 = user?.EX5182; // ff hot fix delete and re-order products
    const EX5937 = user?.EX5937; // ff split purchase
    const EX6017 = user?.EX6017; // ff vendors

    const handlerModalMinimunPurchase = () => {
        if (EX4856) {
            setShowModalMinimunPurchase(!showModalMinimunPurchase);
        }
    };

    const drugManufacturerBelowAmount = () => {
        if (EX4856) {
            return drugManufacturer.filter((d: any) => minimunPurchase.includes(d.id));
        }
    };

    const handlerModalMinMaxQuantity = () => {
        setShowModalMinMaxQuantity(!showModalMinMaxQuantity);
    };

    /**
     * Retrieves the unique drug manufacturer IDs for products that have a minimum or maximum quantity specified.
     * @returns An array of unique drug manufacturer IDs.
     */
    const drugManufacturerBelowOrUpperQuantity = () => {
        const ids = productUncheckedWithMinOrMaxRequired()
            ?.map((item) => {
                return Object.values(item?.products)
                    ?.map((product) => {
                        if (!!product?.mincant || !!product?.maxcant) {
                            return product?.drug_manufacturer_id;
                        }
                    })
                    .filter((id) => id); // Filter null values
            })
            .flat(); // Aplana la matriz resultante

        // Delete duplicate IDs
        const uniqueIds = [];
        const idTracker = {};

        ids.forEach((id) => {
            if (!idTracker[id]) {
                idTracker[id] = true;
                uniqueIds.push(id);
            }
        });

        return uniqueIds;
    };

    const removeMinimunPurchase = (value: number) => {
        if (EX4856) {
            if (minimunPurchase?.includes(value)) {
                if (EX5266) {
                    setMinimunPurchase((prevProps) =>
                        prevProps?.filter((drugManufacturerId) => drugManufacturerId !== value),
                    );
                } else {
                    const minimunAmountFilter = minimunPurchase?.filter(
                        (drugManufacturerId) => drugManufacturerId !== value,
                    );
                    setMinimunPurchase(minimunAmountFilter);
                }
            } else {
                return;
            }
        }
    };

    const addMinimunPurchase = (value: number) => {
        if (EX4856) {
            if (!minimunPurchase?.includes(value)) {
                setMinimunPurchase((prevState) => [...prevState, value]);
            } else {
                return;
            }
        }
    };

    const [openModalConfigDistribution, setOpenModalConfigDistribution] = useState(false);
    const [openModalConfigPriorities, setOpenModalConfigPriorities] = useState(false);
    const [showModalPriceWithOutSelection, setShowModalPriceWithOutSelection] = useState(false);
    const modalPriceWithOutSelection = useRef();
    const [creditLimitReach, setCreditLimitReach] = React.useState(
        drugManufacturer?.map(({ id }) => {
            return { id: id, limiteCredit: true };
        }),
    );
    // GET LOCALSTORAGE PARA MOSTRAR MODAL DE RECOMENDDOS AL INICIAR COMP.
    const showModalRecommended = (): boolean => {
        const value = localStorage.getItem('show_recommended_alert');
        if (value === null) return true;
    };
    // SET LOCALSTORAGE PARA MOSTRAR MODAL DE RECOMENDADOS AL INICIAR COMP.
    const setShowModalRecommended = (): void => {
        localStorage.setItem('show_recommended_alert', JSON.stringify(true));
        setHiddenModalRecommended(true);
        return;
    };

    const [recommendations, setRecommendations] = React.useState<any[]>([]);
    const [recommendationsVisible, setRecommendationsVisible] = React.useState<any>([]);
    const [isFetchRehydrate, setIsFetchRehydrate] = useState(false);

    const addNewRecommendation = (item: ProductInterface) => {
        setProductGroups((prevArray: ProductInterface[]) => {
            const newArray = [item, ...prevArray];
            return newArray;
        });
        setProductInfo(item);
    };

    const handleMoveProductInState = (item: ProductInterface, barcode: string) => {
        const result = units?.find((element: any) =>
            element.products.some((product: any) => product.barcode === barcode),
        );

        if (result) {
            return setUnits((oldState) => {
                // Indice / posicion de donde luego se eliminara el producto
                const sourceManufacturerIndex = oldState?.findIndex(({ drugManufacturerId }) => {
                    if (EX5937) {
                        if (drugManufacturerId && result?.drugManufacturerId) {
                            const checkIfBothAreArray =
                                Array.isArray(drugManufacturerId) && Array.isArray(result.drugManufacturerId);
                            if (checkIfBothAreArray) {
                                return drugManufacturerId?.some((id) => result?.drugManufacturerId?.includes(id));
                            }
                        }
                        return drugManufacturerId === result.drugManufacturerId;
                    } else {
                        return drugManufacturerId === result.drugManufacturerId;
                    }
                });

                // No se encontro el indice
                if (sourceManufacturerIndex === -1) {
                    return oldState;
                }

                // Indice / posicion de donde luego se agregara el producto
                const targetManufacturerIndex = oldState?.findIndex(({ drugManufacturerId }) => {
                    if (EX5937) {
                        if (drugManufacturerId && item.drugManufacturerIdSelected) {
                            const checkBothIfArray =
                                Array.isArray(drugManufacturerId) && Array.isArray(item.drugManufacturerIdSelected);
                            if (checkBothIfArray) {
                                return drugManufacturerId?.some((id) => item.drugManufacturerIdSelected?.includes(id));
                            }
                            return drugManufacturerId === item.drugManufacturerIdSelected;
                        }
                    } else {
                        return drugManufacturerId === item.drugManufacturerIdSelected;
                    }
                });

                // No se encontro el indice
                if (targetManufacturerIndex === -1) {
                    return oldState;
                }

                // Eliminar el producto del fabricante original
                const updatedSourceManufacturer = {
                    ...oldState[sourceManufacturerIndex],
                    products: oldState[sourceManufacturerIndex].products.filter(
                        (product) => product.barcode !== barcode,
                    ),
                };

                // Agregar el producto al fabricante de destino
                const productsArrayWithFilter = EX4927_FFS
                    ? [
                          ...oldState[targetManufacturerIndex].products.filter(
                              (product) => product.barcode !== barcode,
                          ),
                          { barcode: barcode, quantity: item?.quantity },
                      ]
                    : [...oldState[targetManufacturerIndex].products, { barcode: barcode, quantity: item?.quantity }];

                const updatedTargetManufacturer = {
                    ...oldState[targetManufacturerIndex],
                    products: productsArrayWithFilter,
                };

                // Actualizar el estado
                const newData = [...oldState];
                newData[sourceManufacturerIndex] = updatedSourceManufacturer;
                newData[targetManufacturerIndex] = updatedTargetManufacturer;
                return newData;
            });
        }
    };

    const setterUnitsByDrugManufacturers = (item: ProductInterface) => {
        const { quantity, drugManufacturerIdSelected, barcode } = item;
        // busqueda elemento en array
        const elementExist = units?.findIndex(({ drugManufacturerId }) => {
            if (EX5937) {
                if (drugManufacturerId && drugManufacturerIdSelected) {
                    const checkBothIfArray =
                        Array.isArray(drugManufacturerId) && Array.isArray(drugManufacturerIdSelected);
                    if (checkBothIfArray) {
                        return drugManufacturerId?.some((id) => drugManufacturerIdSelected?.includes(id));
                    }
                    return drugManufacturerId === item.drugManufacturerIdSelected;
                }
            } else {
                return drugManufacturerId === item.drugManufacturerIdSelected;
            }
        });

        if (elementExist !== -1) {
            return handleMoveProductInState(item, barcode);
        } else {
            // Si el drugManufacturerId no existe, agregar un nuevo objeto al estado
            return setUnits((oldState) => {
                // Verificar si hay algún objeto con el mismo drugManufacturerId
                const sameManufacturerUnits = oldState?.filter((unit) => {
                    if (EX5937) {
                        if (unit.drugManufacturerId && drugManufacturerIdSelected) {
                            const checkUnitsAreArray =
                                Array.isArray(unit?.drugManufacturerId) && Array.isArray(drugManufacturerIdSelected);
                            if (checkUnitsAreArray) {
                                return unit.drugManufacturerId?.some((id) => drugManufacturerIdSelected?.includes(id));
                            }
                            return unit.drugManufacturerId === drugManufacturerIdSelected;
                        }
                    } else {
                        return unit.drugManufacturerId === drugManufacturerIdSelected;
                    }
                });

                if (sameManufacturerUnits?.length > 0) {
                    // Si hay objetos con el mismo drugManufacturerId, sumar las cantidades
                    return oldState.map((unit) => {
                        if (EX5937) {
                            const checkBothIfArray =
                                Array.isArray(drugManufacturerIdSelected) && Array.isArray(unit.drugManufacturerId);
                            if (checkBothIfArray) {
                                if (unit.drugManufacturerId?.some((id) => drugManufacturerIdSelected?.includes(id))) {
                                    unit.products = [...unit.products, { barcode: barcode, quantity: quantity }];
                                }
                            }
                            const checkIfExists =
                                drugManufacturerIdSelected && unit.drugManufacturerId === drugManufacturerIdSelected;
                            if (checkIfExists) {
                                unit.products = [...unit.products, { barcode: barcode, quantity: quantity }];
                            }
                        } else {
                            if (drugManufacturerIdSelected && unit.drugManufacturerId === drugManufacturerIdSelected) {
                                unit.products = [...unit.products, { barcode: barcode, quantity: quantity }];
                            }
                        }
                        return unit;
                    });
                } else {
                    // hot fix when redisegn v3
                    if (EX4927_FFS) {
                        const arrayWithRemoveProduct: any = oldState?.map((drugManufacturer: any) => {
                            drugManufacturer.products = drugManufacturer.products.filter(
                                (product: any) => product.barcode !== barcode,
                            );
                            return drugManufacturer;
                        });

                        // Si no hay objetos con el mismo drugManufacturerId, agregar uno nuevo
                        return [
                            {
                                products: [{ barcode: barcode, quantity: quantity }],
                                drugManufacturerId: drugManufacturerIdSelected,
                            },
                            ...arrayWithRemoveProduct,
                        ];
                    } else {
                        // Si no hay objetos con el mismo drugManufacturerId, agregar uno nuevo
                        return [
                            {
                                products: [{ barcode: barcode, quantity: quantity }],
                                drugManufacturerId: drugManufacturerIdSelected,
                            },
                            ...oldState,
                        ];
                    }
                }
            });
        }
    };

    const newSetterUnitsByDrugManufacturers = React.useCallback(() => {
        const selectedUnits = [];
        products.forEach(({ drugManufacturerIdSelected, barcode, products, quantity }) => {
            if (Array.isArray(drugManufacturerIdSelected)) {
                drugManufacturerIdSelected?.forEach((id) => {
                    let quantitySelected = 0;
                    if (products[id]?.quantity_suggested) {
                        quantitySelected = products[id].quantity_suggested;
                    } else {
                        quantitySelected = quantity;
                    }
                    selectedUnits.push({
                        products: [{ barcode: barcode, quantity: quantitySelected }],
                        drugManufacturerId: id,
                    });
                });
            } else {
                selectedUnits.push({
                    products: [{ barcode: barcode, quantity: quantity }],
                    drugManufacturerId: drugManufacturerIdSelected,
                });
            }
        });

        setUnits(selectedUnits);
    }, [products]);

    const setProductInfo = (item: ProductInterface) => {
        if (EX3771) {
            const productExists = products?.some(({ barcode }) => barcode === item?.barcode);
            if (productExists) {
                //here updates
                updateProduct(item);
                if (EX5937) {
                    newSetterUnitsByDrugManufacturers();
                } else {
                    setterUnitsByDrugManufacturers(item);
                }
            } else {
                if (EX5937) {
                    newSetterUnitsByDrugManufacturers();
                } else {
                    setterUnitsByDrugManufacturers(item);
                }
                setProducts((oldState) => [...oldState, item]);
            }
        } else {
            const productExists = products?.some(({ barcode }) => barcode === item?.barcode);
            if (productExists) {
                updateProduct(item);
            } else {
                setProducts((oldState) => [...oldState, item]);
            }
        }
    };

    const updateProduct = (item: ProductInterface) => {
        const idx = products?.findIndex(({ barcode }) => item?.barcode === barcode);
        const idxByProductsGroups = EX3485 && productGroups?.findIndex(({ barcode }) => item?.barcode === barcode);
        const findGenerics = productGroups?.filter((object) => object?.generics?.length > 0);
        const isGeneric = findGenerics
            ?.map((producto) => {
                return {
                    ...producto,
                    generics: producto?.generics?.filter((generic) => generic.barcode === item?.barcode),
                };
            })
            ?.filter((producto) => producto.generics.length > 0);
        const idxByProductsGroupsGeneric = productGroups?.findIndex(({ barcode }) => isGeneric[0]?.barcode === barcode);

        // validation with FF ON and length for prevention
        if (EX3485 && productGroups.length) {
            // It is necessary to update the product Groups when the
            // quantity is updated because otherwise it will be left with old data
            const newProductGroups: any = productGroups;

            if (isGeneric?.length) {
                newProductGroups[idxByProductsGroupsGeneric].quantity = item?.quantity;
                newProductGroups[idxByProductsGroupsGeneric].products = item?.products;

                setProductGroups(newProductGroups);
            } else {
                newProductGroups[idxByProductsGroups].quantity = item?.quantity;

                if (EX5266) {
                    newProductGroups[idxByProductsGroups].products = item?.products;
                }
            }

            setProductGroups(newProductGroups);
        }

        const newProductArray: ProductInterface[] = EX5267 ? products : [...products];
        newProductArray[idx] = item;
        if (EX3771) {
            setProducts((oldState) => {
                const combinedProducts: any = [...oldState, ...newProductArray];

                // Usar un objeto para realizar un seguimiento de los productos únicos por order_product_id
                const uniqueProductsMap: any = combinedProducts.reduce((map, product) => {
                    map[product.order_product_id] = product;
                    return map;
                }, {});

                // Convertir el objeto de productos únicos de nuevo a un array
                const uniqueProductsArray: any = Object.values(uniqueProductsMap);

                // Actualizar el estado con el array de productos únicos
                return uniqueProductsArray;
            });
        } else {
            setProducts([...newProductArray]);
        }
    };

    const getProductSelected = React.useCallback((): PostProcessRequest[] => {
        if (EX5937) {
            const selectedProduct = [];
            products.forEach((product) => {
                const isArray = Array.isArray(product?.drugManufacturerIdSelected);
                const drugManufacturerId = product?.drugManufacturerIdSelected;
                if (!isArray) {
                    const barcode = product.barcode;
                    const api_product_id = product.products[drugManufacturerId]?.id;
                    const quantity = product?.quantity;
                    const max_quantity = product?.products[drugManufacturerId]?.maxcant;
                    if (!quantity || !api_product_id) {
                        return;
                    }

                    selectedProduct.push({
                        api_product_id,
                        quantity,
                        max_quantity,
                    });
                } else {
                    drugManufacturerId.forEach((id) => {
                        const barcode = product.barcode;
                        const quantity = product?.products[id]?.quantity_suggested || product?.quantity;
                        const max_quantity = product?.products[id]?.maxcant;
                        const api_product_id = product.products[id]?.id;

                        if (!quantity || !api_product_id) {
                            return;
                        }

                        selectedProduct.push({
                            api_product_id,
                            quantity,
                            max_quantity,
                        });
                    });
                }
            });

            return selectedProduct?.filter((product) => product);
        } else {
            return products
                .map((product) => {
                    const drugManufacturerId = product?.drugManufacturerIdSelected;
                    const quantity = product?.quantity;
                    const max_quantity = product?.products[drugManufacturerId]?.maxcant;
                    const api_product_id = product.products[drugManufacturerId]?.id;
                    if (!quantity || !api_product_id) {
                        return;
                    }
                    return {
                        api_product_id,
                        quantity,
                        max_quantity,
                    };
                })
                ?.filter((product) => product);
        }
    }, [products]);

    const getProductQuantity = React.useCallback(() => {
        if (EX5937) {
            return products?.reduce((count, product) => {
                if (product?.drugManufacturerIdSelected?.length > 0 && product?.quantity > 0) {
                    count++;
                }
                return count;
            }, 0);
        } else {
            return products?.reduce((count, product) => {
                if (product?.drugManufacturerIdSelected && product?.quantity > 0) {
                    count++;
                }
                return count;
            }, 0);
        }
    }, [products, enabledDrugManufacturer]);

    const getUnitsSelectedByDrugManufacturerId = React.useCallback(
        (id: DrugManufacturer['id']) => {
            if (EX5937) {
                return newGetUnitsSelectedByDrugManufacturerId(id);
            }
            // Buscar el objeto con el drugManufacturerId correspondiente
            const drugManufacturer = units?.find((unit) => {
                if (EX5937) {
                    return unit?.drugManufacturerId?.includes(id);
                } else {
                    return unit?.drugManufacturerId === id;
                }
            });

            // Si no se encuentra el fabricante, retornar 0
            if (!drugManufacturer) {
                return 0;
            }

            // Sumar las cantidades de todos los productos del fabricante
            const totalQuantity = drugManufacturer?.products?.reduce(
                (accumulatedQuantity, product) => accumulatedQuantity + product?.quantity,
                0,
            );

            return totalQuantity;
        },
        [products, units],
    );

    const newGetUnitsSelectedByDrugManufacturerId = (id: DrugManufacturer['id']) => {
        if (!Array.isArray(units) || units.length === 0) {
            return 0;
        }

        const drugManufacturersUnits = units.filter((unit) => unit.drugManufacturerId === id);

        if (!drugManufacturersUnits || drugManufacturersUnits.length === 0) {
            return 0;
        }

        const totalQuantity = drugManufacturersUnits.reduce((accumulatedQuantity, unit) => {
            if (!Array.isArray(unit.products)) {
                return accumulatedQuantity;
            }

            const unitTotal = unit.products.reduce(
                (productAccumulatedQuantity, product) => productAccumulatedQuantity + (product?.quantity || 0),
                0,
            );

            return accumulatedQuantity + unitTotal;
        }, 0);

        return totalQuantity;
    };

    const getProductSelectedByDrugManufacturerId = React.useCallback(
        (id: DrugManufacturer['id']) => {
            return (
                products?.filter(({ drugManufacturerIdSelected }) => {
                    if (EX5937) {
                        if (Array.isArray(drugManufacturerIdSelected)) {
                            return drugManufacturerIdSelected?.includes(id);
                        }
                        return drugManufacturerIdSelected === id;
                    } else {
                        return drugManufacturerIdSelected === id;
                    }
                }) || []
            );
        },
        [products],
    );

    const getSubtotalById = React.useCallback(
        (id: DrugManufacturer['id']): number => {
            if (EX5937) {
                // este cambio es implementado ya que el amountSelected es el monto selecciónado para un producto,
                // pero esta función necesita retornar el total para esa droguería, amountSelected antes era valido,
                // porque no existia la posibilidad de tener +1 celda seleccionada por producto.
                const subtotalId = getProductSelectedByDrugManufacturerId(id);
                const cellById = subtotalId?.map((item) => item?.products[id]?.total);
                const subtotal = cellById?.reduce((count, value) => (count += value), 0);
                return subtotal;
            } else {
                const subtotalId = getProductSelectedByDrugManufacturerId(id)?.reduce(
                    (count, item) => (count += item?.amountSelected),
                    0,
                );

                return subtotalId;
            }
        },
        [products],
    );

    const getCreditById = React.useCallback(
        (id: DrugManufacturer['id'], selectedItem) => {
            const limitCreditAvailable = drugManufacturer.find((drug) => {
                return drug.id === id;
            })?.credit_available;
            const totalItemSelected = getProductSelectedByDrugManufacturerId(id).find(
                (item) => selectedItem?.barcode === item?.barcode,
            );
            let subtotal = getProductSelectedByDrugManufacturerId(id)?.reduce((count, item) => {
                if (selectedItem?.barcode === item?.barcode) {
                    count += selectedItem?.amountSelected;
                } else {
                    count += item?.amountSelected;
                }
                return count;
            }, 0);

            if (totalItemSelected === undefined) {
                subtotal += selectedItem?.amountSelected;
            }

            return limitCreditAvailable >= subtotal || !limitCreditAvailable;
        },
        [products, drugManufacturer],
    );

    const getWithStockDrugManfucaturersById = React.useCallback(
        (id: DrugManufacturer['id']) => products?.filter((product) => !product?.products[id]),
        [products],
    );

    const getAllUncheckedProducts = useCallback((): ProductInterface[] => {
        if (enabledOnOff) {
            const uncheckedProducts = products?.filter(
                ({ areAllItemChecked, drugManufacturerIdSelected, is_product_in_stock }) => {
                    if (EX5937) {
                        if (Array.isArray(drugManufacturerIdSelected)) {
                            const isEnabled = drugManufacturerIdSelected
                                ?.map((d) => {
                                    return isEnabledDrugManufacturer(d);
                                })
                                ?.some((item) => !!item);
                            return areAllItemChecked && !isEnabled && is_product_in_stock;
                        }
                    } else {
                        const isEnabled = isEnabledDrugManufacturer(drugManufacturerIdSelected);
                        return areAllItemChecked && !isEnabled && is_product_in_stock;
                    }
                },
            );

            return uncheckedProducts;
        }
        const uncheckedProducts = products?.filter(({ areAllItemChecked, is_product_in_stock }) => {
            return areAllItemChecked && is_product_in_stock;
        });

        return uncheckedProducts;
    }, [products]);

    const productHasCheckedQuantity = React.useCallback((): ProductInterface[] => {
        if (enabledOnOff) {
            return products?.filter(
                ({ products, areAllItemChecked, drugManufacturerIdSelected, quantity, isFatherGeneric }) => {
                    const withoutStock = isEmptyObjectAtrribute(products);
                    if (Array.isArray(drugManufacturerIdSelected)) {
                        const isEnabled = EX5937
                            ? drugManufacturerIdSelected
                                  ?.map((d) => {
                                      return isEnabledDrugManufacturer(d);
                                  })
                                  ?.some((item) => !!item)
                            : isEnabledDrugManufacturer(drugManufacturerIdSelected);
                        // add validation for min and max quantity products to exclude them from the list of unchecked products
                        const hasMinMaxProducts = Object.values(products).some((product) => {
                            return product?.mincant > 0 || product?.maxcant > 0;
                        });

                        const isGenericQuantityZero = isFatherGeneric && quantity === 0;
                        if (isGenericQuantityZero) {
                            return false;
                        }

                        return !hasMinMaxProducts && areAllItemChecked && !withoutStock && !isEnabled;
                    }
                },
            );
        }

        return products?.filter(({ products, areAllItemChecked, quantity, isFatherGeneric }) => {
            const withoutStock = isEmptyObjectAtrribute(products);
            // add validation for min and max quantity products to exclude them from the list of unchecked products
            const hasMinMaxChecked = Object.values(products).some((product) => {
                return product?.mincant > 0 || product?.maxcant > 0;
            });

            const isGenericQuantityZero = isFatherGeneric && quantity === 0;
            if (isGenericQuantityZero) {
                return false;
            }

            return !hasMinMaxChecked && areAllItemChecked && !withoutStock;
        });
    }, [products, productGroups]);

    const productUncheckedWithMinOrMaxRequired = useCallback((): ProductInterface[] => {
        if (enabledOnOff) {
            const productsWithMinMaxRequired = products?.filter(
                ({ products, areAllItemChecked, drugManufacturerIdSelected, quantity }) => {
                    if (Array.isArray(drugManufacturerIdSelected)) {
                        const isEnabled = EX5937
                            ? drugManufacturerIdSelected
                                  ?.map((d) => {
                                      return isEnabledDrugManufacturer(d);
                                  })
                                  ?.some((item) => !!item)
                            : isEnabledDrugManufacturer(drugManufacturerIdSelected);
                        // add validation for min and max quantity products to exclude them from the list of unchecked products
                        return Object.values(products).some((product) => {
                            const hasMinCant = product?.mincant !== null && product?.mincant > 0;
                            const hasMaxCant = product?.maxcant !== null && product?.maxcant > 0;
                            return (!!hasMinCant || !!hasMaxCant) && areAllItemChecked && !isEnabled;
                        });
                    }
                },
            );

            return productsWithMinMaxRequired;
        }
        const productsWithMinMaxRequired = products?.filter(({ products, areAllItemChecked, quantity }) => {
            return Object.values(products).some((product) => {
                const hasMinCant = product?.mincant !== null && product?.mincant > 0;
                const hasMaxCant = product?.maxcant !== null && product?.maxcant > 0;
                return (!!hasMinCant || !!hasMaxCant) && areAllItemChecked;
            });
        });

        return productsWithMinMaxRequired;
    }, [products, productGroups]);

    const allProductHasChecked = React.useCallback((): boolean => productHasCheckedQuantity()?.length === 0, [
        products,
    ]);

    const getDrugManufacturerCdo = () => drugManufacturer.filter((d) => d.id === DrugManufacturersId.CentralDeOfertas);

    const filterCdoInProducts = (product) =>
        Object.fromEntries(
            Object.entries(product?.products).filter(([key, value]) => +key !== DrugManufacturersId.CentralDeOfertas),
        );

    const getTempPrice = (product: ProductInterface) => {
        // EX4562 epic cdo
        if (EX4562) {
            let products;
            // just in case
            if (userLocaleCountryCode === COUNTRY.AR) {
                // Get drugManufacturer cdo
                const offersCenter = getDrugManufacturerCdo();
                // It has cdo and is active and is Argentina
                const isActiveCdo = offersCenter?.length > 0 && offersCenter?.[0]?.cdo_status_id === StatusCdo.ENABLED;
                // object cdo filter
                const productsWithoutCdo = filterCdoInProducts(product);
                // set value by return
                products = isActiveCdo ? product?.products : productsWithoutCdo;
            } else {
                products = product?.products;
            }
            return Object.keys(products)
                .map((key) => {
                    const productSelected = products[key];
                    const subtotal = productSelected?.total;
                    if (subtotal) {
                        return subtotal / product?.quantity;
                    }
                })
                ?.filter((item) => item);
        } else {
            const products = product?.products;

            return Object.keys(products)
                .map((key) => {
                    const productSelected = products[key];
                    const subtotal = productSelected?.total;

                    // if (!isEnabledDrugManufacturer(productSelected?.drug_manufacturer_id)) {
                    //   return 0;
                    // }

                    if (subtotal) {
                        if (product?.quantity > 0) {
                            return subtotal / product?.quantity;
                        }
                    }
                })
                ?.filter((item) => item);
        }
    };

    const getEstimatedSavings = (type: 'youCanSave' | 'youAreSaving') => {
        // if (lastDrugManufacturerEnabled()?.length === 1) {
        //   return 0;
        // }
        return Math.abs(
            products
                .map((product) => {
                    const quantity = product?.quantity;
                    const valueSelected = product?.amountSelected;
                    const tmpPrice = getTempPrice(product);
                    let priceRange = 0;

                    if (tmpPrice.length == 0) {
                        return 0;
                    }
                    if (type == 'youAreSaving') {
                        priceRange = Math.max(...tmpPrice);
                    } else {
                        priceRange = Math.min(...tmpPrice);
                    }
                    return (priceRange - valueSelected / quantity) * quantity;
                })
                ?.reduce((count, currentValue) => count + currentValue, 0) || 0,
        );
    };

    const isFailedConnectionByDrugManufacturerId = React.useCallback(
        (drugManufacturerId): boolean => {
            return !!EX4073
                ? extractedDataError?.some(({ id }) => id === drugManufacturerId)
                : drugManufacturersFailedList.some(({ id }) => id === drugManufacturerId);
        },
        [extractedDataError, drugManufacturersFailedList],
    );

    const isEnabledDrugManufacturer = React.useCallback(
        (drugManufacturerId): boolean => {
            const drugManufacturer = enabledDrugManufacturer?.find(({ id }) => id === drugManufacturerId);
            return drugManufacturer?.enabled;
        },
        [enabledDrugManufacturer],
    );

    const isCreditAvailable = React.useCallback(
        (drugManufacturerId): boolean => {
            const drug = drugManufacturer?.find(({ id }) => id === drugManufacturerId);
            return drug?.credit_available > 0 || drug?.credit_available === null;
        },
        [drugManufacturer],
    );

    const lastDrugManufacturerEnabled = React.useCallback(
        () =>
            enabledDrugManufacturer?.filter(
                (item) => item.enabled === true && !isFailedConnectionByDrugManufacturerId(item.id),
            ),
        [enabledDrugManufacturer],
    );

    const cleanProducts = React.useCallback(() => {
        setProducts([]);
    }, [products, productGroups]);

    const isAnyDrugManufacturerFailed = React.useCallback(
        () => drugManufacturersFailedList?.some(({ responseStatus }) => responseStatus === 'error'),
        [drugManufacturersFailedList],
    );

    const removeItem = async (barcode, orderId) => {
        if (EX5942) {
            setTimeout(() => {
                setDisabledButtonMakeOrderByDelete(true);
                let filteredProduct = products.find((e) => barcode == e.barcode);

                let filteredProducts = products.filter((e) => filteredProduct.barcode !== e.barcode);
                let filteredProductGroups = productGroups?.filter((e) => filteredProduct.barcode !== e.barcode);
                let filteredUnits = units.map((item) => {
                    return {
                        ...item,
                        products: item.products.filter((product) => product.barcode !== barcode),
                    };
                });

                setProducts(filteredProducts);
                setProductGroups(filteredProductGroups);
                setUnits(filteredUnits);
                !EX4927 && setOriginalProductGroups(filteredProductGroups);
                EX4927 && setOriginalProductGroups(filteredProductGroups);
                const productId = filteredProduct.order_product_id;
                deleteItemT(orderId, productId);
            }, 200);
        } else {
            setDisabledButtonMakeOrderByDelete(true);
            let filteredProduct = products.find((e) => barcode == e.barcode);

            let filteredProducts = products.filter((e) => filteredProduct.barcode !== e.barcode);
            let filteredProductGroups = productGroups?.filter((e) => filteredProduct.barcode !== e.barcode);
            let filteredUnits = units.map((item) => {
                return {
                    ...item,
                    products: item.products.filter((product) => product.barcode !== barcode),
                };
            });

            setProducts(filteredProducts);
            setProductGroups(filteredProductGroups);
            setUnits(filteredUnits);
            !EX4927 && setOriginalProductGroups(filteredProductGroups);
            EX4927 && setOriginalProductGroups(filteredProductGroups);
            const productId = filteredProduct.order_product_id;
            deleteItemT(orderId, productId);
        }
    };

    const deleteItemT = (orderId, productId) => {
        const timeoutDelete = setTimeout(async () => {
            await deleteItem(orderId, productId);
            if(EX6410) {
                setLoadingProduct(false)
            }
            setDisabledButtonMakeOrderByDelete(false);
        }, 5000);
        setDeleteItemTimeout(timeoutDelete);
    };

    const undoItem = async (barcode, orderId, index = 0) => {
        if (EX5182) {
            // get product
            let filteredProduct = products.find((e) => barcode == e.barcode);
            // search product in products
            let filteredProducts = products.filter((e) => filteredProduct.barcode !== e.barcode);
            // search product in product-groups
            let filteredProductGroups = productGroups?.filter((e) => filteredProduct.barcode !== e.barcode);
            // push in correct position
            filteredProducts.splice(index, 0, filteredProduct);
            filteredProductGroups.splice(index, 0, filteredProduct);
            // set states
            setProductGroups(filteredProductGroups);
            setProducts(filteredProducts);
            !EX4927 && setOriginalProductGroups(filteredProducts);
            EX4927 && setOriginalProductGroups(filteredProducts);
        } else {
            let filteredProduct = products.find((e) => barcode == e.barcode);
            let filteredProducts = products.filter((e) => filteredProduct.barcode !== e.barcode);
            let filteredProductGroups = productGroups?.filter((e) => filteredProduct.barcode !== e.barcode);

            filteredProducts.push(filteredProduct);
            filteredProductGroups.push(filteredProduct);

            if (!EX5266) {
                filteredProducts.sort((a, b) => a.description.localeCompare(b.description));
                filteredProductGroups.sort((a, b) => a.description.localeCompare(b.description));
            }
            setProductGroups(filteredProductGroups);
            setProducts(filteredProducts);
            !EX4927 && setOriginalProductGroups(filteredProducts);
            EX4927 && setOriginalProductGroups(filteredProducts);
        }
    };

    const handleClearFilters = () => {
        const updatedFilters = filters.map((filter) => {
            return {
                ...filter,
                isChecked: false,
            };
        });
        setFilters(updatedFilters);
        if (searchTerms.length > 0) {
            setSearchTerms('');
        }
    };

    const filtersByTerms = () => {
        if (!!searchTerms && searchTerms.trim().length > 0) {
            const filteredByTerms = productGroups.filter((group) => {
                const filterText = searchTerms.toLowerCase();
                if (!!searchTerms && searchTerms.trim().length > 0) {
                    return (
                        (group?.barcode && group?.barcode.includes(searchTerms)) ||
                        (group?.description && group?.description.toLowerCase().includes(filterText)) ||
                        (group?.laboratory && group?.laboratory.toLowerCase().includes(filterText)) ||
                        (group?.drug && group?.drug.toLowerCase().includes(filterText))
                    );
                }
                return true;
            });

            if (filteredByTerms.length === 0) {
                setWithoutSearchings(true);
            }
            return filteredByTerms;
        } else {
            setWithoutSearchings(false);
            return productGroups;
        }
    };

    const isThereAnyGenericProductWithZero = React.useCallback(() => {
        const getProductGenerics = products?.some(({ quantity, generics }) => {
            return generics?.length > 0 && quantity === 0;
        });

        return getProductGenerics;
    }, [products, productGroups]);

    useEffect(() => {
        // initialize once and save the original product groups
        if (EX4927 && originalProductGroups.length === 0 && productGroups.length > 0) {
            setOriginalProductGroups(productGroups);
        } else if (!EX4927 && originalProductGroups.length === 0 && productGroups.length > 0) {
            setOriginalProductGroups(productGroups);
        }
    }, [productGroups]);

    useEffect(() => {
        if (filters.length === 0) {
            return;
        }

        const isDiscountChecked = filters.find((filter) => filter.name === 'Con descuento')?.isChecked;
        const isOutOfStockChecked = filters.find((filter) => filter.name === 'Sin stock')?.isChecked;
        const isGenericChecked = filters.find((filter) => filter.name === 'Con genéricos')?.isChecked;

        if (isDiscountChecked || isOutOfStockChecked || isGenericChecked) {
            const filteredProducts = products.filter((product) => {
                let matches = true;

                if (isDiscountChecked) {
                    matches =
                        matches &&
                        Object.values(product.products).some((product) => {
                            return (
                                (product && product.agreement) ||
                                (product?.discounts && product?.discounts?.length > 0) ||
                                (product &&
                                    product.agreement &&
                                    product?.discounts &&
                                    product?.discounts?.length > 0 &&
                                    product?.discount &&
                                    //@ts-ignore
                                    product?.discount?.length > 0)
                            );
                        });
                }
                if (isOutOfStockChecked) {
                    matches = matches && !product.is_product_in_stock;
                }
                if (isGenericChecked) {
                    matches = matches && product?.generics?.length > 0;
                }
                return matches;
            });

            setIsFilteringByOutofStock(isOutOfStockChecked);
            setProductGroups(filteredProducts);
            const checkedCount = filters.filter((filter) => filter.isChecked)?.length;
            setWithoutSearchings(checkedCount > 0 && filteredProducts.length === 0);
        } else if (EX4927) {
            // if you applied any filters and then you remove them, the original product groups are restored
            setIsFilteringByOutofStock(false);
            setWithoutSearchings(false);
            // if add a new recommended product update the product groups and the original product groups state
            if (productGroups?.length > originalProductGroups?.length) {
                setOriginalProductGroups(productGroups);
            } else {
                setProductGroups(originalProductGroups);
            }
        } else if (!EX4927) {
            // if you applied any filters and then you remove them, the original product groups are restored
            setIsFilteringByOutofStock(false);
            setWithoutSearchings(false);
            // if add a new recommended product update the product groups and the original product groups state
            if (productGroups?.length > originalProductGroups?.length) {
                // setProducts(productGroups);
                setOriginalProductGroups(productGroups);
            } else {
                setProductGroups(originalProductGroups);
            }
        }
    }, [filters, products]);

    // deprecated
    const fetchEnabledDrugManufacturers = async (orderId) => {
        const enabledDrugManufacturer = await getEnabledDrugManufacturers(orderId);
        const response = enabledDrugManufacturer?.data?.data;
        const oneDisabledDrugManufacturer = response.some((item) => {
            return item.enabled === false;
        });
        if (oneDisabledDrugManufacturer) setEnabledOnOff(true);

        setEnabledDrugManufacturer(response);
    };

    // deprecated
    const fetchDrugManufacturers = async (orderId) => {
        const enabledDrugManufacturer = await getEnabledDrugManufacturers(orderId);
        const response = enabledDrugManufacturer?.data?.data;
        setDrugManufacturer(response);
    };

    const selectAllProductByDMId = React.useCallback(
        (drug_manufacturer_id: number) => {
            const productsSelected = products
                ?.map((product, index) => {
                    if (product?.quantity !== 0) {
                        const currentProductSelected =
                            product.products[drug_manufacturer_id] !== null ? drug_manufacturer_id : product.lowest[0];
                        return {
                            ...product,
                            drugManufacturerIdSelected: currentProductSelected,
                        };
                    }
                })
                .filter((x) => x !== undefined);
            setProducts([...productsSelected]);
        },
        [products, productGroups],
    );

    const moreThanFiveDrugManufacturers = React.useCallback(() => {
        return drugManufacturer?.length > 5 || width <= 1280;
    }, [drugManufacturer]);

    // detect products without stock and validate CdO status
    const checkStockByProducts = () => {
        const allProductsInStock = productGroups?.some((product) => {
            return product?.is_product_in_stock === true;
        });
        if (!allProductsInStock && productGroups?.length > 0 && !isFilteringByOutofStock) {
            setAllProductsOutOfStock(true);
        } else {
            setAllProductsOutOfStock(false);
        }
    };

    useEffect(() => {
        checkStockByProducts();
    }, [productGroups]);

    // new effect
    useEffect(() => {
        setEnabledDrugManufacturer(drugManufacturersData);
        setDrugManufacturer(drugManufacturersData);
        setEnabledOnOff(oneDisabledDrugManufacturerData);
    }, [drugManufacturersData]);

    // new effect
    useEffect(() => {
        if (!!isFetchingUpdate) {
            mutateDrugManufacturers();
        }
    }, [isFetchingUpdate]);

    React.useEffect(() => {
        if (!products) {
            return;
        }

        const drugManufacturers = drugManufacturer
            ?.map(({ id }) => productGroups.map((product) => Boolean(product?.products[id])).some((i) => i))
            ?.reduce((accoumulator, currentValue) => {
                return currentValue ? accoumulator + 1 : accoumulator;
            }, 0);

        setASingleDrugsHasStock(!(drugManufacturers > 1 || drugManufacturers === 0));

        setSubtotal(products?.reduce((count, { amountSelected }) => (count += amountSelected || 0), 0));

        setEstimatedSavings({
            youAreSaving: getEstimatedSavings('youAreSaving'),
            youCanSave: getEstimatedSavings('youCanSave'),
        });

        if (EX5937) {
            newSetterUnitsByDrugManufacturers();
        }
    }, [products]);

    React.useEffect(() => {
        if (undoTimeout) {
            clearTimeout(deleteItemTimeout);
            setUndoTimeout(false);
            setDisabledButtonMakeOrderByDelete(false);
        }
    }, [undoTimeout]);

    return (
        <ProductComparisonContext.Provider
            value={{
                creditLimitReach,
                getCreditById,
                isCreditAvailable,
                aSingleDrugsHasStock,
                productGroups,
                setProductGroups,
                drugManufacturersFailedList,
                setDrugManufacturersFailedList,
                products,
                setProductInfo,
                subtotal,
                getSubtotalById,
                getProductQuantity,
                productHasCheckedQuantity,
                allProductHasChecked,
                estimatedSavings,
                getProductSelected,
                isFailedConnectionByDrugManufacturerId,
                getProductSelectedByDrugManufacturerId,
                getWithStockDrugManfucaturersById,
                retryLoading,
                setRetryLoading,
                loading,
                setLoading,
                remainingAttempts,
                setRemainingAttempts,
                isAnyDrugManufacturerFailed,
                setColumnMargin,
                columnMargin,
                setColumnWidth,
                columnWidth,
                removeItem,
                isEnabledDrugManufacturer,
                enabledDrugManufacturer,
                setEnabledDrugManufacturer,
                enabledOnOff,
                setEnabledOnOff,
                lastDrugManufacturerEnabled,
                cleanProducts,
                undoItem,
                setUndoTimeout,
                drugManufacturer,
                selectAllProductByDMId,
                selectAllState,
                setSelectAllState,
                moreThanFiveDrugManufacturers,
                disabledButtonMakeOrderByDelete,
                showModalRecommended,
                setShowModalRecommended,
                hiddenModalRecommended,
                setHiddenModalRecommended,
                addNewRecommendation,
                recommendations,
                setRecommendations,
                recommendationsVisible,
                setRecommendationsVisible,
                setIsFetchRehydrate,
                isFetchRehydrate,
                isManualChecked,
                setIsManualChecked,
                filtersByTerms,
                getUnitsSelectedByDrugManufacturerId,
                units,
                updateProductGroup,
                allProductsOutOfStock,
                setAllProductsOutOfStock,
                minimunPurchase,
                addMinimunPurchase,
                removeMinimunPurchase,
                withoutSearchings,
                setSearchTerms,
                searchTerms,
                setIsCheck,
                isCheck,
                handleClearFilters,
                drugManufacturerBelowAmount,
                modalPriceWithOutSelection,
                openModalConfigDistribution,
                setOpenModalConfigDistribution,
                openModalConfigPriorities,
                setOpenModalConfigPriorities,
                showModalPriceWithOutSelection,
                setShowModalPriceWithOutSelection,
                showModalMinimunPurchase,
                handlerModalMinimunPurchase,
                productUncheckedWithMinOrMaxRequired,
                showModalMinMaxQuantity,
                setShowModalMinMaxQuantity,
                handlerModalMinMaxQuantity,
                drugManufacturerBelowOrUpperQuantity,
                getAllUncheckedProducts,
                manuallySelectedProductIds,
                setManuallySelectedProductIds,
                recommendationLimit,
                setRecommendationLimit,
                filters,
                setFilters,
                showChildren,
                setShowChildren,
                allGenerics,
                setAllGenerics,
                updateParentProduct,
                setUpdateParentProduct,
                isThereAnyGenericProductWithZero,
                insufficientCreditIDs,
                setInsufficientCreditIDs,
                openedInsufficientCreditIDs,
                setOpenedInsufficientCreditIDs,
                manuallyOpenedCreditTip,
                setManuallyOpenedCreditTip,
                loadingProduct,
                setLoadingProduct,
                ...props,
            }}
        >
            {children}
        </ProductComparisonContext.Provider>
    );
}

export function useProductComparison() {
    const context = React.useContext(ProductComparisonContext);
    if (!context) {
        throw new Error('useProductComparison must be used within a ProducComparisonProvider');
    }

    return context;
}
