// context/ModalContext.tsx
import { useToast } from '@commons/EXComponentsLibrary';
import { DrugManufacturer } from '@commons/modules/CardConnectionOffline/types';
import {
    credentialData,
    initialValue,
} from '@commons/modules/InitialConfig/PointOfSales/NewCredentialCard/NewCredentialCardConnectionV3/NewFormConnectionV3/schema/formShemaModel';
import {
    addImageManufacturedCredentials,
    countInstancesV3,
    credentialTypesInOrder,
    handleSwitch,
} from '@commons/modules/InitialConfig/PointOfSales/NewCredentialCard/NewCredentialCards.helpers';
import {
    createApiCredentialV2,
    deleteApiCredential,
    getCatalogs,
    saveCatalogForDrugManufacturer,
    updateApiCredentialV2,
    updateCatalogForDrugManufacturer,
} from 'api';
import { useAlert } from 'context/alertContext/alert-context';
import { useToggleSwitch } from 'context/toggleSwitch/ToggleSwitchContext';
import { t } from 'i18next';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { apiPharmaInstance } from 'utils/axios';
import { COUNTRY } from 'utils/country';
import { typesOfErrors } from 'utils/drugManufacturers/errors';
import { requiresCatalog, requiresClientType, requiresDeposit } from 'utils/drugManufacturers/specialDrugs';
import { Status, useAsync, useDrugManufacturersWithoutPagination, useModal, useUser } from 'utils/hooks';
import useIsLoading from 'utils/hooks/useIsLoading';
import { DrugManufacturersId, StatusCdo } from 'utils/interfaces';
import { mutateFromCache } from 'utils/swr';

export interface FormValueCredentialType {
    username: string;
    password: string;
    function?: string;
    client_identifier?: string;
    catalog_id?: string;
    available_branches?: string;
    dispatch_address?: any;
    payment_method_id?: string;
    file?: File;
}

interface ModalCredentialsContextProps {
    addressSelected: any;
    areAllDrugsFromAR: boolean;
    catalogs: string[];
    clearOnClose: () => void;
    clearSaveCatalogForDrugmanufacturer: () => void;
    clearStatus: () => void;
    clearUpdateCatalogForDrugmanufacturer: () => void;
    clientTypes: string[];
    credentialId: number;
    dataCreateCredential: any;
    deposits: string[];
    drugConnection: { image: string; id: number; type: any };
    drugManufacturerUrl: () => string;
    errorCreate: any;
    errorMessage: { msgError: string; status: null | number };
    finishConnection: boolean;
    formError: any;
    formValue: FormValueCredentialType;
    fullUser: FormValueCredentialType;
    getDrugInformationById: () => {
        formatStatus: 'connected' | 'disconnected' | 'error';
        credentialTypes: any;
        speciality: string;
        missing_format: boolean;
        hasSomeError: boolean;
        has_wrong_username_password: boolean;
        drugmanufacturerId: number;
    };
    getNameById: (id: number) => string;
    handlerAlertBeforeDelete: (credentialId: number, nameType: string, drugName: string, drugId: number) => void;
    handlerDeleteCredential: (credentialId: number) => void;
    handlerDrugConnection: (image: string, id: number, type: any, parsedType?: string) => void;
    handlerDrugFormConnection: (typeConnectionName: string, id: number) => void;
    handlerFormSubmit: () => void;
    handlerOnCloseModal: () => void;
    handlerVendorConnection: (id: number) => void;
    initializeForm: (credential_types: string[], id?: number, typeNameCredential?: string) => void;
    isCdOEnabled: boolean;
    isEditing: boolean;
    isLoading: boolean;
    isLoadingLoginExtraInformation: boolean;
    isSaveSuccess: boolean;
    isSubmitting: boolean;
    modalUser: any;
    mutateDrugManufacturers: () => void;
    orderByCredentialType: any;
    sendValues: boolean;
    setCredentialId: (credentialId: number) => void;
    setDrugConnection: (drugConnection: { image: string; id: number; type: any }) => void;
    setDrugmanufacturersWithPercentage: (drugmanufacturersWithPercentage: any) => void;
    setDrugmanufacturersWithPriorities: (drugmanufacturersWithPriorities: any) => void;
    setErrorMessage: (errorMessage: { status: null | number; msgError: string }) => void;
    setFinishConnection: (finishConnection: boolean) => void;
    setFormError: (formError: any) => void;
    setFormValue: (formValue: FormValueCredentialType) => void;
    setFullUser: (fullUser: FormValueCredentialType) => void;
    setIsEditing: (isEditing: boolean) => void;
    setIsLoadingLoginExtraInformation: (isLoadingLoginExtraInformation: boolean) => void;
    setIsSaveSuccess: (isSaveSuccess: boolean) => void;
    setIsSubmitting: (isSubmitting: boolean) => void;
    setSendValues: (sendValues: boolean) => void;
    setShowPreventAlert: (showPreventAlert: boolean) => void;
    setVisibilityForm: (visibilityForm: boolean) => void;
    showPreventAlert: boolean;
    startLoading: () => void;
    statusApiCreateCredential: any;
    statusApiCredentialDeleted: any;
    statusSaveCatalogForDrugmanufacturer: any;
    statusUpdateCatalogForDrugmanufacturer: any;
    stopLoading: () => void;
    toggleModal: any;
    toggleModalVendor: any;
    togglePreventModal: () => void;
    typeConnectionName: { typeOfConnection: string; id: number };
    usePostApiCredential: (credentials: any) => any;
    visibilityForm: boolean;
    setIsFetchingStateOnOff: any;
    isFetchingStateOnOff: any;
    isFetching: boolean;
    drugManufacturers: any;
}

const ModalCredentialsContext = createContext<ModalCredentialsContextProps | undefined>(undefined);

interface ModalCredentialsProviderProps {
    children: React.ReactNode;
}

export const ModalCredentialsProvider: React.FC<ModalCredentialsProviderProps> = ({ children }) => {
    // context
    const { setIsToggleOn } = useToggleSwitch();
    const { hasAuthorization, user } = useUser({});
    const toaster = useToast();
    const { show } = useAlert();

    // states for the connection form
    const [typeConnectionName, setTypeConnection] = useState({
        typeOfConnection: '',
        id: null,
    });
    const [visibilityForm, setVisibilityForm] = useState(false);
    const [finishConnection, setFinishConnection] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        status: null,
        msgError: '',
    });
    const [fullUser, setFullUser] = useState<FormValueCredentialType>(initialValue);
    const [showPreventAlert, setShowPreventAlert] = useState(false);
    const [credentialId, setCredentialId] = useState(null);
    const [formValue, setFormValue] = useState<FormValueCredentialType>(initialValue);
    const [formError, setFormError] = useState({});

    const [catalogs, setCatalogs] = useState([]);
    const [clientTypes, setClientTypes] = useState([]);
    const [deposits, setDeposits] = useState([]);
    const [sendValues, setSendValues] = useState(false);
    const [areAllDrugsFromAR, setAreAllDrugsFromAR] = useState(false);
    const [addressSelected, setAddressSelected] = useState(null);

    const [drugConnection, setDrugConnection] = useState({
        image: '',
        id: null,
        type: '',
    });

    const [isEditing, setIsEditing] = useState(false);

    const [drugmanufacturersWithPercentage, setDrugmanufacturersWithPercentage] = useState<any>([]);

    const [drugmanufacturersWithPriorities, setDrugmanufacturersWithPriorities] = useState<any>([]);

    const [isLoadingLoginExtraInformation, setIsLoadingLoginExtraInformation] = useState(false);

    const [isFetchingStateOnOff, setIsFetchingStateOnOff] = useState({
        visibility: null,
        state: false,
    });
    const [hasFetched, setHasfetched] = useState(false);
    // hooks
    const toggleModal = useModal();
    const toggleModalVendor = useModal();
    const modalUser = useModal();

    const { isLoading, startLoading, stopLoading } = useIsLoading();

    const {
        run: runCreateApiCredential,
        status: statusApiCreateCredential,
        error: errorCreate,
        clear: clearStatus,
        data: dataCreateCredential,
    } = useAsync({});

    const { run: runApiCredentialDeleted, status: statusApiCredentialDeleted } = useAsync({});

    const {
        run: runSaveCatalogForDrugmanufacturer,
        status: statusSaveCatalogForDrugmanufacturer,
        error: errorSaveCatalogForDrugmanufacturer,
        data: dataSaveCatalogForDrugmanufacturer,
        clear: clearSaveCatalogForDrugmanufacturer,
    } = useAsync({});

    const {
        run: runUpdateCatalogForDrugmanufacturer,
        status: statusUpdateCatalogForDrugmanufacturer,
        error: errorUpdateCatalogForDrugmanufacturer,
        data: dataUpdateCatalogForDrugmanufacturer,
        clear: clearUpdateCatalogForDrugmanufacturer,
    } = useAsync({});

    const router = useRouter();
    const { posId } = router?.query;

    const EX6303 = user?.EX6303;
    const path = ['/[clientId]/efficient-purchase', '/[clientId]/efficient-purchase/[posId]'].includes(router.pathname);
    const additionalParams = EX6303 && path ? ['', '1', 'home'] : [];

    const reValidateDrugManufacturers = false;

    const { drugManufacturers, mutateDrugManufacturers, isFetching } = useDrugManufacturersWithoutPagination(
        posId,
        reValidateDrugManufacturers,
        ...additionalParams,
    );

    const isCdOEnabled =
        drugManufacturers?.data?.find((drugmanfacturer) => drugmanfacturer?.id === DrugManufacturersId.CentralDeOfertas)
            ?.cdo_status_id === StatusCdo.ENABLED;

    // if the user it's admin then fetch the catalogs
    const fetchGetCatalogs = async () => {
        const response = await getCatalogs();

        // parse the response to the format that the select component needs
        const optionsCatalogs = response?.data?.data?.map((catalog) => ({
            name: catalog.name,
            id: catalog.id,
        }));
        setCatalogs(optionsCatalogs);
    };

    // attach image
    const onlines = drugManufacturers?.data?.filter((d: any) => !d.is_offline);
    const addImageManufactured = addImageManufacturedCredentials({ data: onlines });

    const offlines = drugManufacturers?.data?.filter((d) => d.is_offline);
    const orderByCredentialTypeOfflines: DrugManufacturer[] = addImageManufacturedCredentials({ data: offlines });

    useEffect(() => {
        if (!!isFetchingStateOnOff.state) {
            toaster.showToaster({
                message: {
                    title:
                        isFetchingStateOnOff?.visibility === 'ON'
                            ? t('newWidgetHome.toastTitleTextOn')
                            : t('newWidgetHome.toastTitleTextOff'),
                    description:
                        isFetchingStateOnOff?.visibility === 'ON'
                            ? t('newWidgetHome.toastSubtextOn')
                            : t('newWidgetHome.toastSubtextOff'),
                },
                duration: 3000,
                type: 'success',
            });
        }

        setHasfetched(true);

        setIsFetchingStateOnOff((prevState) => ({
            ...prevState, // Mantiene el resto del estado
            state: false, // Cambia solo la propiedad `state` a `false`
        }));
    }, [isFetchingStateOnOff.state]);

    useEffect(() => {
        if (hasFetched) {
            mutateDrugManufacturers();

            setHasfetched(false);
        }
    }, [hasFetched]);

    // orders array
    const orderByCredentialType = addImageManufactured?.map((elem) => {
        // filter the credential types by connection
        const filteredCredentialTypesByConnection = elem?.credential_types?.filter(
            (credential) => credential.name !== 'api',
        );
        const orderTypeCredential = credentialTypesInOrder(filteredCredentialTypesByConnection);

        // add new attribute to the arr mention connected or not
        const validateIfHasUsername = orderTypeCredential.filter((elem) => elem.username);

        const hasTicketFormatValidating = orderTypeCredential?.filter((elem) => elem?.format_ticket);

        const errorUserPasswordConnection = orderTypeCredential?.filter((elem) => elem?.errors?.length > 0);
        const newConnected = elem?.credential_types?.some((item) => item?.api_credential_id !== null);

        return {
            hasConnected: validateIfHasUsername,
            connected: newConnected,
            orderTypeCredential,
            ...elem,
            credential_types: filteredCredentialTypesByConnection.filter((credential) => {
                if (elem.id === DrugManufacturersId.Cofaloza) {
                    return credential.name !== 'scraper_np';
                }
                return credential;
            }),
            hasTicketFormatValidating: hasTicketFormatValidating,
            errorUserPasswordConnection: errorUserPasswordConnection[0]?.errors,
        };
    });

    const totalCounterInstancesV3 =
        orderByCredentialType?.length >= 0 && orderByCredentialTypeOfflines?.length >= 0
            ? countInstancesV3([...orderByCredentialType, ...orderByCredentialTypeOfflines])
            : 0;

    // init the values of deposits and client types
    useEffect(() => {
        const depositsLoaded = orderByCredentialType?.find(
            (drugmanufacturer) => drugmanufacturer?.deposits?.length > 0 && requiresDeposit(drugmanufacturer?.id),
        )?.deposits;

        setDeposits(depositsLoaded);

        const clientTypesLoaded = orderByCredentialType?.find(
            (drugmanufacturer) =>
                drugmanufacturer?.client_types?.length > 0 && requiresClientType(drugmanufacturer?.id),
        )?.client_types;

        setClientTypes(clientTypesLoaded);

        if (drugManufacturers) {
            const booleanDrugsAR = drugManufacturers?.data?.every((drug) => drug?.country_alfa_2 === COUNTRY.AR);
            setAreAllDrugsFromAR(booleanDrugsAR);
        }
    }, [orderByCredentialType]);

    // handler

    const handlerDrugConnection = (image = '', id = null, type = null, parsedType = '') => {
        if (+id === DrugManufacturersId.AlmacenDeDrogas && hasAuthorization) {
            fetchGetCatalogs();
        }
        // obtenemos los metodos disponibles para la droguería
        // solo si tiene 1 metodo de conexión levantamos el modal directamente para conectarte con el metodo correspondiente
        if (type?.length === 1) {
            // formateamos el nombre
            const nameFormatted = handleSwitch(type[0]?.name, type);
            // carga la imagen de la droguería
            setDrugConnection({ image: image, id: id, type: type[0]?.name });
            // levanta modal de conexión
            return handlerDrugFormConnection(nameFormatted, type[0]?.id);
        } else {
            const hasAnyConnection = type?.some((t) => t.username);

            if (+id === DrugManufacturersId.DrogueriasDelSud && hasAnyConnection) {
                const nameFormatted = handleSwitch(parsedType, type);
                const typeId = type.filter((type) => type.name === parsedType);
                setDrugConnection({ image: image, id: id, type: parsedType });
                return handlerDrugFormConnection(nameFormatted, typeId[0]?.id);
            } else {
                setDrugConnection({ image: image, id: id, type: type });
                setVisibilityForm(false);
            }
        }
    };

    const handlerDrugFormConnection = (typeConnectionName = '', id = null) => {
        setTypeConnection({ typeOfConnection: typeConnectionName, id: id });
        setVisibilityForm(true);
    };

    const handlerVendorConnection = (id) => {
        setDrugConnection({ image: '', id: id, type: 'vendor' });
        toggleModalVendor.toggle();
    };

    const handlerDeleteCredential = (credentialId) => {
        try {
            toggleModal.visibility && toggleModal.toggle();
            credentialData.credentialId = '';
            credentialData.nameType = '';
            credentialData.drugName = '';
            initialValue.username = '';
            initialValue.password = '';
            initialValue.function = '';
            initialValue.client_identifier = '';
            initialValue.catalog_id = '';
            runApiCredentialDeleted(deleteApiCredential(credentialId));
            mutateFromCache('/api/api-credential');
            mutateDrugManufacturers();
        } catch (ex) {
            console.error(ex.message);
        }
    };

    const handlerAlertBeforeDelete = (credentialId, nameType, drugName, drugId) => {
        const atLeastTwoDrugmanufacturers = totalCounterInstancesV3 === 2;
        toggleModal.visibility && toggleModal.toggle();
        toggleModalVendor.visibility && toggleModalVendor.toggle();
        let text = '';
        const hasPercentage = drugmanufacturersWithPercentage?.includes(drugId);
        const hasPriorities: any = drugmanufacturersWithPriorities?.assigned?.some(
            (item) => item.drug_manufacturer_id === drugId,
        );

        const baseText = t('pointOfSale.modal.deleteCredentialWithoutAutoConfig', { drugName: drugName });
        const textTwoDrugmanufacturersConnected = t('pointOfSale.modalDeleteCredentialBody7', {
            drugName: drugName,
        });

        let additionalText = '';
        if (hasPercentage && hasPriorities) {
            additionalText = t('pointOfSale.modalDeleteCredentialBody3');
        } else if (hasPriorities) {
            additionalText = t('pointOfSale.modalDeleteCredentialBody4');
        } else if (hasPercentage) {
            additionalText = t('pointOfSale.modalDeleteCredentialBody5');
        }

        text = atLeastTwoDrugmanufacturers ? textTwoDrugmanufacturersConnected : `${baseText} ${additionalText}`;

        show({
            className: '',
            title: t('settings.editPointsOfSale.sett_deleteCredential', {
                web: nameType,
                drugManufacturerName: drugName,
            }),
            cancel: true,
            size: 'xs',
            body: text,
            onConfirm: () => handlerDeleteCredential(credentialId),
            onCancel: () => {},
            async: undefined,
            action: t('pointOfSale.modal.confirmDeleteTextButton'),
        });
    };

    const displayToaster = (message: string, typeToast) => {
        toaster.showToaster({
            message: { title: message },
            duration: 5000,
            type: typeToast,
        });
    };

    const reHydrateStatusApi = (statusApiCredentialDeleted) => {
        if (statusApiCredentialDeleted === Status.Resolved) {
            displayToaster('Desconectaste la web de la droguería.', 'success');
            mutateFromCache('/api/api-credential');

            mutateDrugManufacturers();
        }
    };

    const getDrugInformationById = () => {
        const dataDrugmanufacturer = orderByCredentialType?.find((item) => item.id === drugConnection.id);
        const { missing_format, hasSomeError, has_wrong_username_password } = typesOfErrors(dataDrugmanufacturer);

        const formatStatus = dataDrugmanufacturer?.connected ? 'connected' : 'disconnected';
        const credentialTypes = dataDrugmanufacturer?.credentialTypes;
        const speciality = dataDrugmanufacturer?.speciality;

        return {
            formatStatus: formatStatus as 'connected' | 'disconnected' | 'error',
            credentialTypes,
            speciality,
            missing_format,
            hasSomeError,
            has_wrong_username_password,
            drugmanufacturerId: dataDrugmanufacturer?.id,
        };
    };

    const togglePreventModal = () => {
        setShowPreventAlert(!showPreventAlert);
        toggleModal.toggle();
    };

    const clearOnClose = () => {
        // closeModal();
        setErrorMessage({ status: null, msgError: '' });
        setTimeout(() => {
            initialValue.username = '';
            initialValue.password = '';
            initialValue.function = '';
            initialValue.client_identifier = '';
            initialValue.catalog_id = '';
            initialValue.available_branches = '';
            initialValue.dispatch_address = '';
            initialValue.payment_method_id = '';
            setFormValue(initialValue);
            setFormError({});
            stopLoading();
            // clear status of uploader file
            setIsSaveSuccess(false);
            setIsSubmitting(false);
            setFinishConnection(false);
            setIsToggleOn(false);
        }, 500);
        clearStatus();
        clearSaveCatalogForDrugmanufacturer();
        clearUpdateCatalogForDrugmanufacturer();
        mutateDrugManufacturers();

        if (showPreventAlert) {
            setShowPreventAlert(false);
        } else {
            toggleModal.toggle();
        }
    };

    const handlerOnCloseModal = () => {
        const hasUnsavedChanges =
            Object.values(formValue).some((value) => value !== '') &&
            Object.values(initialValue).every((value) => {
                return value === '';
            });
        const isConnected = requiresCatalog(+drugConnection.id)
            ? dataSaveCatalogForDrugmanufacturer?.data?.data || dataUpdateCatalogForDrugmanufacturer?.data?.data
            : dataCreateCredential?.data?.data;
        if (hasUnsavedChanges && !isConnected) {
            toggleModal.toggle();
            setShowPreventAlert(true);
        } else {
            clearOnClose();
        }
    };

    const handlerFormSubmit = async () => {
        setIsSubmitting(true);

        if (formValue) {
            setFullUser(formValue);
        }

        try {
            setErrorMessage({ status: null, msgError: '' });
            startLoading(); // Esto triggerea el loading del button

            if (isEditing) {
                if (requiresCatalog(drugConnection?.id)) {
                    runUpdateCatalogForDrugmanufacturer(
                        updateCatalogForDrugManufacturer(
                            parseInt(credentialData.credentialId),
                            +posId,
                            formValue?.client_identifier,
                            drugConnection?.id,
                            parseInt(formValue?.catalog_id),
                        ),
                    );
                } else {
                    runCreateApiCredential(
                        updateApiCredentialV2(parseInt(credentialData.credentialId), {
                            ...formValue,
                            username: formValue ? formValue.username : fullUser.username,
                            password: formValue ? formValue.password : fullUser.password,
                            client_identifier: formValue ? formValue.client_identifier : fullUser.client_identifier,
                            point_of_sale_id: +posId,
                            drug_manufacturer_id: drugConnection?.id,
                            type_id: !!typeConnectionName && typeConnectionName.id,
                            confirmed: formValue?.username === initialValue?.username ? true : sendValues,
                            dispatch_address:
                                typeof formValue?.dispatch_address === 'string'
                                    ? {
                                          value: formValue?.dispatch_address,
                                          label: formValue?.dispatch_address,
                                      }
                                    : formValue?.dispatch_address,
                        }),
                    );
                }
            } else {
                if (requiresCatalog(drugConnection?.id)) {
                    runSaveCatalogForDrugmanufacturer(
                        saveCatalogForDrugManufacturer(
                            +posId,
                            formValue?.client_identifier,
                            drugConnection?.id,
                            parseInt(formValue?.catalog_id),
                        ),
                    );
                } else {
                    runCreateApiCredential(
                        createApiCredentialV2({
                            ...formValue,
                            username: formValue ? formValue.username : fullUser.username,
                            password: formValue ? formValue.password : fullUser.password,
                            client_identifier: formValue ? formValue.client_identifier : fullUser.client_identifier,
                            point_of_sale_id: +posId,
                            drug_manufacturer_id: drugConnection?.id,
                            type_id: !!typeConnectionName && typeConnectionName.id,
                            confirmed: sendValues,
                            dispatch_address:
                                typeof formValue?.dispatch_address === 'string'
                                    ? {
                                          value: formValue?.dispatch_address,
                                          label: formValue?.dispatch_address,
                                      }
                                    : formValue?.dispatch_address,
                        }),
                    );
                }
            }

            // mutateFromCache('/api/api-credential');
            // mutateDrugManufacturers();

            setIsSubmitting(false);

            return;
        } catch (ex) {
            console.error(ex);
            setErrorMessage({ status: null, msgError: '' });
            stopLoading();
        }
    };

    const drugManufacturerUrl = () => {
        const drugManufacturer = orderByCredentialType?.filter((item: any) => item.id === drugConnection.id);
        if (drugManufacturer?.length) {
            return drugManufacturer[0]?.url;
        } else {
            return '';
        }
    };

    const getNameById = (id) => {
        const credentialName = orderByCredentialType?.find((item) => item.id === id);
        return credentialName?.name;
    };

    // initial
    const initializeForm = (credential_types, id, typeNameCredential) => {
        const credential =
            credential_types.length > 1
                ? credential_types.find((credential) => credential.name === typeNameCredential)
                : credential_types[0];

        if (!credential) return;

        const address = credential.dispatch_addresses?.find((address) => address.selected === true);

        Object.assign(initialValue, {
            username: credential.username,
            password: credential.password,
            function: credential.function,
            client_identifier: credential.client_identifier,
            dispatch_address: {
                value: address?.dispatch_address?.description,
                label: address?.dispatch_address?.description,
            },
            payment_method_id: credential.payment_method,
            available_branches: credential.available_branches,
            catalog_id: credential.catalog_id,
        });

        Object.assign(credentialData, {
            credentialId: credential.api_credential_id,
            nameType: credential.name,
            drugName: getNameById(id),
        });
    };

    const postFetcher = async (url, credentials) => {
        try {
            const response = await apiPharmaInstance.post(url, credentials);
            if (response?.status === 400) {
                return { status: 400, data: 'Usuario y/o contraseña incorrectos' };
            } else if (response?.status >= 401) {
                return response?.data?.error.message;
            } else if (response?.status === 200 || response?.status === 201) {
                return response.data.data;
            }
        } catch (error) {
            // Manejo específico de errores de Axios u otros errores
            if (error.response) {
                // El servidor respondió con un código de estado que cae fuera del rango 2xx
                throw {
                    status: error.response.data?.error?.code,
                    message: error.response.data?.data[0],
                };
            } else if (error.request) {
                // La solicitud fue hecha pero no hubo respuesta
                throw new Error('No se recibió respuesta del servidor');
            } else {
                // Algo pasó al configurar la solicitud
                throw new Error(error.message);
            }
        }
    };

    const usePostApiCredential = (credentials) => {
        const { data, error, mutate: mutateRequestInformation } = useSWR(
            credentials ? ['/api/api-credential/login', credentials] : null,
            async (url, credentials) => {
                setIsLoadingLoginExtraInformation(true);
                try {
                    const data = await postFetcher(url, credentials);
                    return data;
                } catch (error) {
                    throw error;
                } finally {
                    setIsLoadingLoginExtraInformation(false);
                }
            },
            {
                revalidateOnFocus: false,
                revalidateOnMount: false,
                revalidateOnReconnect: false,
                dedupingInterval: 60000,
                refreshInterval: 0,
                shouldRetryOnError: false,
            },
        );

        return {
            loginExtraInformation: data,
            isError: error,
            mutateRequestInformation,
        };
    };

    useEffect(() => {
        if (!!drugConnection?.id) {
            const isEdit = requiresCatalog(drugConnection?.id)
                ? initialValue?.client_identifier !== ''
                : initialValue?.username !== '' && initialValue?.password !== '';
            setIsEditing(isEdit);
        } else {
            setIsEditing(false);
        }
    }, [drugConnection]);

    useEffect(() => {
        reHydrateStatusApi(statusApiCredentialDeleted);
    }, [statusApiCredentialDeleted]);

    return (
        <ModalCredentialsContext.Provider
            value={{
                addressSelected,
                areAllDrugsFromAR,
                catalogs,
                clearOnClose,
                clearSaveCatalogForDrugmanufacturer,
                clearStatus,
                clearUpdateCatalogForDrugmanufacturer,
                clientTypes,
                credentialId,
                dataCreateCredential,
                deposits,
                drugConnection,
                drugManufacturerUrl,
                errorCreate,
                errorMessage,
                finishConnection,
                formError,
                formValue,
                fullUser,
                getDrugInformationById,
                getNameById,
                handlerAlertBeforeDelete,
                handlerDeleteCredential,
                handlerDrugConnection,
                handlerDrugFormConnection,
                handlerFormSubmit,
                handlerOnCloseModal,
                handlerVendorConnection,
                initializeForm,
                isCdOEnabled,
                isEditing,
                isLoading,
                isLoadingLoginExtraInformation,
                isSaveSuccess,
                isSubmitting,
                modalUser,
                mutateDrugManufacturers,
                orderByCredentialType,
                sendValues,
                setCredentialId,
                setDrugConnection,
                setDrugmanufacturersWithPercentage,
                setDrugmanufacturersWithPriorities,
                setErrorMessage,
                setFinishConnection,
                setFormError,
                setFormValue,
                setFullUser,
                setIsEditing,
                setIsLoadingLoginExtraInformation,
                setIsSaveSuccess,
                setIsSubmitting,
                setSendValues,
                setShowPreventAlert,
                setVisibilityForm,
                showPreventAlert,
                startLoading,
                statusApiCreateCredential,
                statusApiCredentialDeleted,
                statusSaveCatalogForDrugmanufacturer,
                statusUpdateCatalogForDrugmanufacturer,
                stopLoading,
                toggleModal,
                toggleModalVendor,
                togglePreventModal,
                typeConnectionName,
                usePostApiCredential,
                visibilityForm,
                setIsFetchingStateOnOff,
                isFetchingStateOnOff,
                drugManufacturers,
                isFetching,
            }}
        >
            {children}
        </ModalCredentialsContext.Provider>
    );
};

export const useModalCredentialsContext = () => {
    const context = useContext(ModalCredentialsContext);
    if (!context) {
        throw new Error('useModalContext must be used within a ModalProvider');
    }
    return context;
};
