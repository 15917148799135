import { postDrugManufacturersOnOff } from 'api/drugManufacturersOnOff/';
import { t } from 'i18next';

/**
 * Auxiliary function that exposes the `getStylesAndIcons` function
 * for use in other modules.
 *
 * @returns {Object} - An object containing the `getStylesAndIcons` function.
 */
export const auxiliaryPaper = () => {
    /**
     * This function returns the icon and the corresponding style class
     * based on the provided status.
     *
     * @param {Record<string, string>} style - Object containing CSS styles.
     * @param {string} status - Component status ('ON', 'OFF', or default value).
     * @returns {{ icon: string, classNameStyle: string }} - An object containing the icon URL and the style class name.
     */
    const getStylesAndIcons = (
        style: Record<string, string>,
        status: 'ON' | 'OFF' | string,
    ): { icon: string; classNameStyle: string } => {
        // Define a map for status to avoid repetitive code
        const statusMap: Record<string, { icon: string; classNameStyle: string }> = {
            ON: {
                icon: '/assets/images/power_settings_ON.svg',
                classNameStyle: style['icon-container-ON'],
            },
            OFF: {
                icon: '/assets/images/power_settings_OFF.svg',
                classNameStyle: style['icon-container-OFF'],
            },
        };

        // Default to the icon and style for unknown statuses
        const defaultStatus = {
            icon: '/assets/images/power_settings.svg',
            classNameStyle: style['icon-container'],
        };

        // Return the matching status or the default one
        return statusMap[status] || defaultStatus;
    };

    /**
     * Toggles the drug manufacturer status between ON and OFF.
     *
     * This function sends a request to toggle the status of a drug manufacturer
     * (identified by `drugManufacturerId`) between ON and OFF. It updates the UI
     * visibility based on the response and shows a success notification using
     * the `toaster` component.
     *
     * @param {number} drugManufacturerId - The ID of the drug manufacturer.
     * @param {QueryParams} query - Query parameters, including `posId`.
     * @param {SetVisibility} setVisibility - Function to update the UI visibility
     * between 'ON' and 'OFF'.
     * @param {Toaster} toaster - The toaster component used to display success
     * messages.
     * @param {Function} setIsFetchingStateOnOff - Function to update the fetching state of the ON/OFF toggle.
     */
    const handlerToggleSubmit = async (drugManufacturerId, query, setVisibility, toaster, setIsFetchingStateOnOff) => {
        const payload = {
            drug_manufacturer_id: drugManufacturerId,
        };
        const data: any = await postDrugManufacturersOnOff(query.posId, payload);
        if (data?.status === 200) {
            const { is_on } = data?.data?.data;
            setVisibility(is_on ? 'ON' : 'OFF');
            setIsFetchingStateOnOff({ state: true, visibility: is_on ? 'ON' : 'OFF' });
        }
    };

    return {
        getStylesAndIcons,
        handlerToggleSubmit,
    };
};
