import { createContext, useCallback, useContext, useEffect, useState } from 'react';
// components
import { useToast } from '@commons/EXComponentsLibrary';
// hooks
import { useRouter } from 'next/router';
// apis
import { postSendEmailVendors } from 'api/vendors/vendors';

type ContextProps = {
    data: any[];
    currentIndex: number;
    animations: any;
    handleClickButton: (item) => void;
    handleNext: () => void;
    handlePrevious: () => void;
    idsDeleted: number[];
    loadState: (payload: any) => void;
    fetching: boolean;
    setFetching: (v: boolean) => void;
    requestVendor: (nameVendor: string, idVendor: number) => void;
};

const defaultValues: ContextProps = {
    data: [],
    currentIndex: 0,
    animations: {},
    handleClickButton: (item) => {},
    handleNext: () => {},
    handlePrevious: () => {},
    idsDeleted: [],
    loadState: (payload: any) => {},
    fetching: false,
    setFetching: (v: boolean) => {},
    requestVendor: (nameVendor: string, idVendor: number) => {},
};

type Props = {
    children: React.ReactNode;
};

const ListBoxDrugManufacturersContext = createContext<ContextProps>(defaultValues);

export const ListBoxDrugManufacturersProvider = ({ children }: Props) => {
    const { push, query } = useRouter();
    const { showToaster } = useToast();

    /* #region states */
    const [data, setData] = useState<any[]>(defaultValues.data);
    const [currentIndex, setCurrentIndex] = useState<number>(defaultValues.currentIndex);
    const [animations, setAnimations] = useState<any>(defaultValues.animations);
    const [idsDeleted, setIdsDeleted] = useState<number[]>(defaultValues.idsDeleted);
    const [fetching, setFetching] = useState<boolean>(defaultValues.fetching);
    /* #endregion */

    // FN encargada de mover el paginador si se queda sin elementos en la ultima division
    const handleMovePagination = (newData: any): void => {
        if (newData?.slice(currentIndex, currentIndex + 3)?.length === 0) {
            return handlePrevious();
        }
        return;
    };

    // FN encargada de eliminar el item del array de data
    const removeItemById = (id) => {
        setData((prevData) => {
            const newData = prevData.filter((item) => item.id !== id);
            handleMovePagination(newData);
            return newData;
        });
    };

    // FN encargada de activar animación
    const triggerAnimation = useCallback(
        (id) => {
            // activamos animacion para el elemento
            setAnimations((prev) => ({ ...prev, [id]: true }));
            setTimeout(() => {
                // desactivamos animacion
                setAnimations((prev) => ({ ...prev, [id]: false }));
                // removemos item del array de data
                removeItemById(id);
            }, 450);
        },
        [data],
    );

    const requestVendor = async (nameVendor: string, idVendor: number) => {
        try {
            setIdsDeleted((prevState) => [...prevState, idVendor]);
            const response: any = await postSendEmailVendors(idVendor, { point_of_sale_id: query?.posId });
            if (response?.status === 200) {
                triggerAnimation(idVendor);
                return showToaster({
                    type: 'success',
                    message: {
                        title: `Enviamos tu solicitud a ${nameVendor}.`,
                        description: 'Te avisaremos por mail cuando responda.',
                    },
                });
            }
            return response;
        } catch (ex) {
            console.error(ex.message);
        }
    };

    // FN encargada del click en btn de accion de elemento
    const handleClickButton = async (item: any) => {
        if (!!item) {
            if (item?.type_id === 3) {
                // vendor
                requestVendor(item?.name, item?.id);
            } else if (item?.type_id === 2 || item?.type_id === 1) {
                // no vendor
                push({
                    pathname: `/${query?.clientId}/settings/POS/${query?.posId}/posDetails`,
                    query: { dId: item?.id },
                });
                return;
            }
        }
    };

    // carga state inicial
    const loadState = useCallback((payload: any) => {
        setData(payload);
    }, []);

    // paginacion siguiente
    const handleNext = (): void => {
        const newIndex = currentIndex + 3;
        if (newIndex < data.length) {
            setCurrentIndex(newIndex);
        }
    };

    // paginacion previa
    const handlePrevious = (): void => {
        const newIndex = currentIndex - 3;
        if (newIndex >= 0) {
            setCurrentIndex(newIndex);
        }
    };

    const value: ContextProps = {
        data,
        currentIndex,
        animations,
        handleClickButton,
        handlePrevious,
        handleNext,
        idsDeleted,
        loadState,
        fetching,
        setFetching,
        requestVendor,
    };

    return (
        <ListBoxDrugManufacturersContext.Provider value={value}>{children}</ListBoxDrugManufacturersContext.Provider>
    );
};

export const useListBoxDrugManufacturersContext = () => useContext(ListBoxDrugManufacturersContext);
