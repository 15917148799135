import { apiPharmaInstance } from 'utils/axios';
import { ResponseType, DrugManufacturer, ApiProduct, PaymentMethodPerDelivery, DeliveryTime } from 'common/types';
import * as Sentry from '@sentry/nextjs';
import { captureException } from 'utils/hooks/captureSentryError';

export interface PostProcessRequest {
    api_product_id: number;
    quantity: number;
}

interface IProcessAPI {
    orderId: string;
    times?: DeliveryTime[];
    paymentMethods?: PaymentMethodPerDelivery[];
}

type PaymentMethodInput = {
    delivery_id: number;
    payment_method_id: number;
};

export const processApi = async ({ orderId, times, paymentMethods }: IProcessAPI) => {
    const EX3394 = process.env.NEXT_PUBLIC_EX3394 === 'true';
    const delivery_times = times || [];
    const payment_methods: PaymentMethodInput[] =
        paymentMethods && paymentMethods.length > 0
            ? paymentMethods.map((method) => {
                  const selectedPayment = method.paymentMethods?.find((payment) => payment.selected);
                  return {
                      delivery_id: method.deliveryId,
                      payment_method_id: selectedPayment!.id,
                  };
              })
            : [];

    if (!!EX3394) {
        const transaction = Sentry.startTransaction({
            name: 'Confirmation order',
        });

        Sentry.configureScope((scope) => {
            scope.setSpan(transaction);
        });
        try {
            return await apiPharmaInstance.post(`/api/orders/${orderId}/process`, { delivery_times, payment_methods });
        } catch (err) {
            captureException(err);
        } finally {
            transaction.finish();
        }
    } else {
        try {
            return await apiPharmaInstance.post(`/api/orders/${orderId}/process`, { delivery_times, payment_methods });
        } catch (err) {
            console.error(err.message);
        }
    }
};
export const processApis = async (orderId) => {
    const EX3394 = process.env.NEXT_PUBLIC_EX3394 === 'true';

    if (!!EX3394) {
        const transaction = Sentry.startTransaction({
            name: 'Confirmation order',
        });

        Sentry.configureScope((scope) => {
            scope.setSpan(transaction);
        });
        try {
            return await apiPharmaInstance.post(`/api/orders/${orderId}/process`);
        } catch (err) {
            captureException(err);
        } finally {
            transaction.finish();
        }
    } else {
        try {
            return await apiPharmaInstance.post(`/api/orders/${orderId}/process`);
        } catch (err) {
            console.error(err.message);
        }
    }
};

export const retryApiCall = async (orderId) =>
    await apiPharmaInstance.post<ResponseType<DrugManufacturer[]>>(`/api/orders/${orderId}/retry-apicalls`);

export const startProductGroupsJob = async (orderId) => {
    try {
        return await apiPharmaInstance.get(`/api/orders/${orderId}/product-groups`);
    } catch (error) {
        console.log(error.message);
    }
};

export const updateProductGroup = async (
    orderId,
    request: {
        barcode: string;
        quantity: number;
    },
) =>
    await apiPharmaInstance.post(`/api/orders/${orderId}/product-group`, {
        ...request,
    });

export const updateProductGroupWithForceSuggested = async (
    orderId,
    request: {
        barcode: string;
        quantity: number;
        force_suggested: number;
    },
) =>
    await apiPharmaInstance.post(`/api/orders/${orderId}/product-group`, {
        ...request,
    });

export const fill = async (orderId, products: PostProcessRequest[]) =>
    await apiPharmaInstance.post(`/api/orders/${orderId}/fill`, { products });

export const deliveryFill = async (orderId) => {
    await apiPharmaInstance.post(`/api/orders/${orderId}/delivery-times`);
};

export const clean = async (orderId) =>
    await apiPharmaInstance.post<
        ResponseType<{
            products: {
                quantity: number;
                api_product: ApiProduct;
            }[];
        }>
    >(`/api/orders/${orderId}/clean`);

export const downloadFileOrderDelivery = async (orderId, deliveryId) =>
    await apiPharmaInstance({
        url: `/api/orders/${orderId}/deliveries/${deliveryId}/download`,
        method: 'GET',
        responseType: 'blob',
    });

export const cancel = async (orderId) =>
    await apiPharmaInstance.post<ResponseType<any>>(`/api/orders/${orderId}/cancel`);

export const deleteItem = async (orderId, productId) => {
    await apiPharmaInstance.delete<ResponseType<any>>(`/api/orders/${orderId}/order-products/${productId}`);
};

export const getEnabledDrugManufacturers = async (orderId, typeId = '') => {
    let url = `api/orders/${orderId}/drugManufacturers`;
    if (typeId) {
        url += `?filters[type_id]=${typeId}`;
    }
    return await apiPharmaInstance.get<ResponseType<any>>(url);
};

export const getEnabledDrugManufacturersWithCredentials = async (orderId, posId, typeId = '') => {
    let url = `api/orders/${orderId}/drugManufacturers?sorts=name:asc&filters[point_of_sale_id]=${posId}&includes=credentials&rows=1000`;
    if (typeId) {
        url += `&filters[type_id]=${typeId}`;
    }
    return await apiPharmaInstance.get<ResponseType<any>>(url);
};

export const postEnabledDrugManufacturers = async (orderId, enabledDrugManufacturers) =>
    await apiPharmaInstance.post<ResponseType<any>>(`api/orders/${orderId}/drugManufacturers`, {
        enabled_drug_manufacturers: enabledDrugManufacturers,
    });

export const downloadFileResult = async (orderId) =>
    await apiPharmaInstance.get<ResponseType<any>>(`api/orders/${orderId}/download-res`, { responseType: 'blob' });

export const downloadMissingFileResult = async (orderId) =>
    await apiPharmaInstance.get<ResponseType<any>>(`api/orders/${orderId}/download-fal`, { responseType: 'blob' });

export const downloadZippedOrder = async (orderId) =>
    await apiPharmaInstance.get<ResponseType<any>>(`api/orders/${orderId}/download-xls-res`, { responseType: 'blob' });

export const getPercentageOfToleranceOrderID = async (
    orderId?: number | string | string[],
): Promise<ResponseType<any>> => {
    try {
        return await apiPharmaInstance.get(`/api/orders/${orderId}/distribution-percentages`);
    } catch (e) {
        if (e.response.status === 404) {
            return undefined;
        }
    }
};

export const postPercentageOfToleranceOrderID = async (orderId?: number, data?: any): Promise<ResponseType<any>> => {
    try {
        return await apiPharmaInstance.post(`/api/orders/${orderId}/distribution-percentages`, data);
    } catch (e) {
        if (e.response.status === 404) {
            return undefined;
        }
    }
};

export const deletePercentageOfToleranceOrderID = async (orderId?: number): Promise<ResponseType<any>> => {
    return await apiPharmaInstance.delete(`/api/orders/${orderId}/distribution-percentages`);
};

export const getOrders = async (queryString, rows = '', pages = '') => {
    try {
        return await apiPharmaInstance.get(`api/orders?${queryString}&rows=${rows}&page=${pages}`);
    } catch (ex) {
        console.log(ex.message);
    }
};
export const getDeliveries = async (queryString, rows = '', pages = '') => {
    try {
        return await apiPharmaInstance.get(`api/deliveries?${queryString}&rows=${rows}&page=${pages}`);
    } catch (ex) {
        console.log(ex.message);
    }
};

export const getOrderDetails = async (orderId) => {
    return await apiPharmaInstance.get(`api/orders/${orderId}/showStats`);
};

export const DownloadAllOrder = async (orderId) => {
    return await apiPharmaInstance.get(`api/orders/${orderId}/download-attachment-res-file`);
};

export const DownloadFailedOrder = async (orderId) => {
    return await apiPharmaInstance.get(`api/orders/${orderId}/download-attachment-fal-file`);
};

export const detailsDeliveries = async (deliveryId, orderId) => {
    return await apiPharmaInstance.get(`api/orders/${orderId}/deliveries/${deliveryId}/detail`);
};

// add product recommended in product group
export const addRecommended = async (
    orderId,
    orderProduct,
    request: {
        barcode: string;
        is_included: boolean;
    },
) =>
    await apiPharmaInstance.patch(`api/orders/${orderId}/product-group/order-product/${orderProduct}`, {
        ...request,
    });

export const updateProductGroupBySplit = async (orderId, request: { barcode: string; allow_split: boolean }) =>
    await apiPharmaInstance.post(`/api/orders/${orderId}/product-group`, { ...request });

export const downloadOrderByExtension = async (orderId: number, extension: 'res' | 'csv' | 'txt' | 'xlsx') =>
    await apiPharmaInstance.get<ResponseType<any>>(`api/orders/${orderId}/download-res-ext/${extension}`, {
        responseType: 'blob',
    });
