import { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { t } from 'i18next';
// contexts
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
import { useToggleSwitch } from 'context/toggleSwitch/ToggleSwitchContext';
// components
import { useToast } from '@commons/EXComponentsLibrary';
// utils && hooks
import { formatObjectByType } from './utils';
import { useUser } from 'utils/hooks';
// apis
import { postSendEmailVendors } from 'api/vendors/vendors';
import { getDrugManufacturersHome } from 'api';
import { usePointOfSale } from 'context/point-of-sale-context';
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';
import { validateTypeConnection } from 'utils/drugManufacturers/validateTypeConnection';
import { typesOfErrorsMultiple } from 'utils/drugManufacturers/errors';

type ContextProps = {
    data: any;
    handleClickButton: (item) => void;
    fetching: boolean;
    setFetching: (v: boolean) => void;
    requestVendor: (nameVendor: string, idVendor: number) => void;
    triggerModal: (
        drug: any,
        id: number,
        credential_types: any[],
        typeId: number,
        isUnknownCredential?: boolean,
        isErrorCredential?: boolean,
    ) => void;
    removeItem: (id: number, section: string) => void;
};

const defaultValues: ContextProps = {
    data: {},
    handleClickButton: (item) => {},
    fetching: false,
    setFetching: (v: boolean) => {},
    requestVendor: (nameVendor: string, idVendor: number) => {},
    triggerModal: (
        drug: any,
        id: number,
        credential_types: any[],
        typeId: number,
        isUnknownCredential?: boolean,
        isErrorCredential?: boolean,
    ) => {},
    removeItem: (id: number, section: string) => {},
};

type Props = {
    children: React.ReactNode;
};

const DrugManufacturerWidgetContext = createContext<ContextProps>(defaultValues);

export const DrugManufacturerWidgetProvider = ({ children }: Props) => {
    const { push, query } = useRouter();
    const { showToaster } = useToast();
    const { user } = useUser({});
    const EX6303 = user?.EX6303; // epic rediseño modulo
    const { toggleSwitch } = useToggleSwitch();

    /* #region states */
    const [data, setData] = useState<any>([]);
    const [fetching, setFetching] = useState<boolean>(defaultValues.fetching);
    const {
        handlerDrugConnection,
        handlerVendorConnection,
        initializeForm,
        setErrorMessage,
        setFormError,
        toggleModal,
        drugManufacturers,
        isFetching,
        mutateDrugManufacturers,
    } = useModalCredentialsContext();
    /* #endregion */

    const { pointOfSale } = usePointOfSale();
    // zetti
    const isZetti = isZettiFormat(pointOfSale);
    const isGestorERP = isGestorERPFormat(pointOfSale);

    const validateERP = !isZetti && !isGestorERP;

    useEffect(() => {
        if (EX6303 && validateERP) {
            mutateDrugManufacturers();
        }
    }, [query?.posId]);

    useEffect(() => {
        setFetching(isFetching);
        if (!isFetching && drugManufacturers?.data) {
            setData(formatObjectByType(drugManufacturers?.data));
        } else if ((!isFetching && !drugManufacturers?.data) || drugManufacturers?.data?.length === 0) {
            setData([]);
        }
    }, [isFetching]);

    // FN encargada de ejectuar el modal de config
    const triggerModal = (drug, id, credential_types, typeId, isUnknownCredential, isErrorCredential) => {
        const isEditing = credential_types[0].api_credential_id !== null;

        const isMultipleCredential = credential_types?.length > 1;
        const errorsMultiples = isMultipleCredential ? typesOfErrorsMultiple(drug) : null;

        let credentialType = credential_types[0]?.name; // Default to the first credential

        if (isMultipleCredential) {
            const { hasSomeError_first, hasSomeError_second } = errorsMultiples || {};

            if (hasSomeError_first) {
                credentialType = credential_types[0]?.name;
            } else if (hasSomeError_second) {
                credentialType = credential_types[1]?.name;
            }
        }

        // common function
        const openCredentialModal = () => {
            if (typeId === 3) {
                handlerVendorConnection(id);
            } else {
                const parsedType = validateTypeConnection(credentialType);
                handlerDrugConnection('', id, credential_types, parsedType);
                toggleModal.toggle();
            }
        };

        if (isUnknownCredential) {
            toggleSwitch();
            if (isEditing) {
                initializeForm(credential_types, id, credentialType);
            }
            openCredentialModal();
        } else if (isErrorCredential) {
            if (isEditing) {
                setFormError({ username: true, password: true });
                setErrorMessage({
                    status: 400,
                    msgError: t('efficientPurchasing.credentialsError.panelErrorUserOrPassword'),
                });
                initializeForm(credential_types, id, credentialType);
            }
            openCredentialModal();
        } else {
            if (isEditing) {
                initializeForm(credential_types, id, credentialType);
            }
            openCredentialModal();
        }
    };

    const requestVendor = async (nameVendor: string, idVendor: number) => {
        try {
            const response: any = await postSendEmailVendors(idVendor, { point_of_sale_id: query?.posId });
            if (response?.status === 200) {
                showToaster({
                    type: 'success',
                    message: {
                        title: `Enviamos tu solicitud a ${nameVendor}.`,
                        description: 'Te avisaremos por mail cuando responda.',
                    },
                });
                return response;
            }
        } catch (ex) {
            console.error(ex.message);
        }
    };

    // FN encargada del click en btn de accion de elemento
    const handleClickButton = async (item: any) => {
        if (!!item) {
            if (item?.type_id === 3) {
                // vendor
                requestVendor(item?.name, item?.id);
            } else if (item?.type_id === 2 || item?.type_id === 1) {
                // no vendor
                push({
                    pathname: `/${query?.clientId}/settings/POS/${query?.posId}/posDetails`,
                    query: { dId: item?.id },
                });
                return;
            }
        }
    };

    // FN encargada del click en btn de accion de elemento
    const removeItem = (id: number, section: string) => {
        // Obtener el item que vamos a eliminar
        const itemToRemove = data[section]?.filter((item: any) => item.id === id);

        if (itemToRemove?.[0]?.id) {
            // Crear un nuevo array excluyendo el id que queremos eliminar
            const updatedData = {
                ...data,
                [section]: data[section]?.filter((item: any) => item.id !== itemToRemove[0]?.id), // Filtrar el item a eliminar
            };
            // Actualizar el estado principal eliminando el item
            setData(updatedData);
        }
    };

    const value: ContextProps = {
        data,
        handleClickButton,
        fetching,
        setFetching,
        requestVendor,
        triggerModal,
        removeItem,
    };

    return <DrugManufacturerWidgetContext.Provider value={value}>{children}</DrugManufacturerWidgetContext.Provider>;
};

export const useDrugManufacturerWidgetContext = () => useContext(DrugManufacturerWidgetContext);
